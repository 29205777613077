import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { cn } from "@/lib/utils";

const Divlr = ({ className, index = 0, animated = true, children, onMouseOver }) => {
  return animated ? (
    <motion.div
      onMouseOver={onMouseOver}
      initial={{ opacity: 0.0, x: -10 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{
        delay: 0.1 * index,
        duration: 0.3,
        ease: "easeInOut",
      }}
      viewport={{ once: true }}
      className={cn(className)}
    >
      {children}
    </motion.div>
  ) : (
    <div className={cn(className)}>{children}</div>
  );
};

const Divt0to50 = ({ className, index = 0, animated = true, children, onMouseOver }) => {
  return animated ? (
    <motion.div
      onMouseOver={onMouseOver}
      initial={{ opacity: 0.0, x: 0 }}
      whileInView={{ opacity: 0.5, x: 0 }}
      transition={{
        delay: 0.1 * index,
        duration: 0.3,
        ease: "easeInOut",
      }}
      viewport={{ once: true }}
      className={cn(className)}
    >
      {children}
    </motion.div>
  ) : (
    <div className={cn(className)}>{children}</div>
  );
};

const Divbt = ({ className, index = 0, animated = true, children, onMouseOver }) => {
  return animated ? (
    <motion.div
      onMouseOver={onMouseOver}
      initial={{ opacity: 0.0, y: 10 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{
        delay: 0.1 * index,
        duration: 0.3,
        ease: "easeInOut",
      }}
      viewport={{ once: true }}
      className={cn(className)}
    >
      {children}
    </motion.div>
  ) : (
    <div className={cn(className)}>{children}</div>
  );
};

let viewLocations = {};

const DivPage = ({ className, animated = true, overlay, children }) => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(viewLocations[location?.pathname]);

  useEffect(() => {
    setInitialized(true);
    if (!viewLocations[overlay ?? location?.pathname]) {
      setTimeout(() => {
        viewLocations[overlay ?? location?.pathname] = true;
      }, 1000);
    }
  }, []);

  if (animated && !initialized && !viewLocations[location?.pathname]) {
    return null;
  }

  const anim = animated && !viewLocations[overlay ?? location?.pathname];
  return (
    <motion.div
      initial={anim ? { opacity: 0.0, x: overlay ? 5 : -5 } : { opacity: 1, x: 0 }}
      whileInView={anim ? { opacity: 1, x: 0 } : { opacity: 1, x: 0 }}
      transition={anim ? { duration: 0.2, ease: "easeInOut" } : { duration: 0, ease: "easeInOut" }}
      viewport={{ once: true }}
      className={cn(className)}
    >
      {children}
    </motion.div>
  );
};

export { Divlr, Divbt, Divt0to50, DivPage };
