import { LazyEmpty } from "@/lib/navigation";
import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";

import DocNotFound from "./DocNotFound";
import DocSidebar from "./DocSidebar";
const Default = lazy(() => import("@/pages/static/docs/Default"));
const Privacy = lazy(() => import("@/pages/static/docs/Privacy"));
const Terms = lazy(() => import("@/pages/static/docs/Terms"));
const ExtensionQuickStart = lazy(() => import("@/pages/static/docs/ExtensionQuickStart"));
const ExtensionProfit = lazy(() => import("@/pages/static/docs/ExtensionProfit"));
const ExtensionEmail = lazy(() => import("@/pages/static/docs/ExtensionEmail"));

function DocMain() {
  return (
    <div className="relative flex min-h-[80vh] flex-row px-8 md:px-12 lg:px-20 xl:px-28 2xl:px-44">
      <div className="fixed top-0 hidden max-h-[100vh] min-h-[100vh] min-w-64 flex-col overflow-y-scroll border-r pb-10 pr-8 pt-24 dark:border-black lg:flex xl:min-w-72 2xl:min-w-80">
        <DocSidebar />
      </div>
      <div className="w-full px-0 pb-10 pt-24 lg:ml-64 lg:px-10 xl:ml-72 2xl:ml-80">
        <Routes>
          <Route path="/" element={<LazyEmpty Component={Default} />} />
          <Route path="/extension/calculate-profit" element={<LazyEmpty Component={ExtensionProfit} />} />
          <Route path="/extension/email-brokers" element={<LazyEmpty Component={ExtensionEmail} />} />
          <Route path="/extension/quickstart" element={<LazyEmpty Component={ExtensionQuickStart} />} />
          <Route path="/policies/privacy" element={<LazyEmpty Component={Privacy} />} />
          <Route path="/policies/terms" element={<LazyEmpty Component={Terms} />} />
          <Route path="*" element={<DocNotFound />} />
        </Routes>
      </div>
    </div>
  );
}

export default DocMain;
