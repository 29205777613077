import { Loader2 } from "lucide-react";

function RedirectProgress() {
  return (
    <div className={"flex h-screen w-screen items-center justify-center"}>
      <Loader2 className="h-10 w-10 animate-spin text-black/10 transition-all" />
    </div>
  );
}

export default RedirectProgress;
