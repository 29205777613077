import { createSlice } from "@reduxjs/toolkit";

export const navSlice = createSlice({
  name: "nav",
  initialState: {
    sidebarVisible: false,
    dialogVisible: false,
    dialogParams: {},
    breadcrumbs: [],
  },
  reducers: {
    triggerSidebar: (state) => {
      state.sidebarVisible = !state.sidebarVisible;
    },
    saveBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload;
    },
    triggerDialog: (state, action) => {
      state.dialogVisible = true;
      state.dialogParams = action.payload;
    },
    closeDialog: (state) => {
      state.dialogVisible = false;
    },
  },
});

export const { triggerSidebar, saveBreadcrumbs, triggerDialog, closeDialog } = navSlice.actions;

export default navSlice.reducer;
