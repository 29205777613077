"use client";
import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { cn } from "@/lib/utils";

const transition = {
  type: "spring",
  mass: 0.5,
  damping: 11.5,
  stiffness: 100,
  restDelta: 0.001,
  restSpeed: 0.001,
};

export const MenuItem = ({ setActive, active, item, delay, children, className }) => {
  return (
    <motion.div
      onMouseEnter={() => setActive(item)}
      className={cn("relative", className)}
      initial={{ opacity: 0.0, x: -10 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ delay: delay ?? 0.3, duration: 0.4, ease: "easeInOut" }}
      viewport={{ once: true }}
    >
      <motion.p
        transition={{ duration: 0.3 }}
        className="cursor-pointer text-sm font-medium text-black hover:opacity-[0.9] dark:text-white"
      >
        {item}
      </motion.p>
      {active !== null && (
        <div>
          {active === item && (
            <div className="absolute left-1/2 top-[calc(100%_+_1.2rem)] -translate-x-1/2 transform pt-4">
              <motion.div
                transition={transition}
                layoutId="active" // layoutId ensures smooth animation
                className="overflow-hidden rounded-xl border border-black/[0.2] bg-white shadow-xl backdrop-blur-sm dark:border-white/[0.2] dark:bg-gray-800/90"
              >
                <motion.div
                  layout // layout ensures smooth animation
                  className="h-full w-max p-2"
                >
                  {children}
                </motion.div>
              </motion.div>
            </div>
          )}
        </div>
      )}
    </motion.div>
  );
};

export const Menu = ({ setActive, className, children }) => {
  return (
    <nav
      onMouseLeave={() => setActive(null)} // resets the state
      className={cn(
        "boder relative flex justify-center space-x-2 rounded-full border-transparent px-8 py-4 shadow-input",
        className,
      )}
    >
      {children}
    </nav>
  );
};

export const ProductItem = ({ title, description, to, setActive }) => {
  return (
    <Link
      to={to}
      className="group flex space-x-1"
      onClick={() => {
        setActive?.(null);
      }}
    >
      <div>
        <p className="font-lg mb-2 text-nowrap font-bold tracking-wide text-black underline-offset-2 group-hover:underline dark:text-white">
          {title}
        </p>
        <p className="max-w-[17rem] font-light leading-relaxed text-neutral-800 dark:text-neutral-300">{description}</p>
      </div>
    </Link>
  );
};

export const HoveredLink = ({ setActive, delay, children, className, ...res }) => {
  return (
    <motion.div
      onMouseEnter={() => setActive(null)}
      initial={{ opacity: 0.0, x: -10 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ delay: delay ?? 0.3, duration: 0.4, ease: "easeInOut" }}
      viewport={{ once: true }}
      className={className}
    >
      <Link
        {...res}
        className="text-nowrap text-sm font-medium text-neutral-700 underline-offset-2 hover:text-black hover:underline dark:text-neutral-200"
      >
        {children}
      </Link>
    </motion.div>
  );
};
