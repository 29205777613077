import { Suspense, lazy, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { WavyBackground } from "@/components/animations/wavy-background";

import AuthError from "@/pages/auth/AuthError";
const SigninWithOAuthNPR = lazy(() => import("@/pages/auth/SigninWithOAuthNPR"));

export function LoadingPage() {
  return <div className="flex h-[100vh] items-center justify-center text-5xl" />;
}

export function LazyWave({ Component }) {
  return (
    <WavyBackground backgroundFill="#f8fafc" containerClassName="h-[85vh] md:h-screen" speed="extra-slow">
      <Suspense>
        <Component />
      </Suspense>
    </WavyBackground>
  );
}

export function LazyEmpty({ Component, element, fallback }) {
  return <Suspense fallback={fallback ?? <LoadingPage />}>{element ?? <Component />}</Suspense>;
}

export function PrivateOnly() {
  const user = useSelector((state) => state.user);
  return user.token ? <Outlet /> : user.error ? <AuthError /> : <LazyWave Component={SigninWithOAuthNPR} />;
}

export function PublicOnly() {
  const user = useSelector((state) => state.user);
  return !user.token ? <Outlet /> : <Navigate to="/app/home" />;
}

export function ScrollToTop({ children, element }) {
  let location = useLocation();
  useEffect(() => window.scrollTo({ top: 0, left: 0, behavior: "instant" }), [location.pathname]);
  return element ?? children ?? <Outlet />;
}
