import { LandingTopSection } from "./LandingTopSection";
import { LandingHowToSection } from "./LandingHowToSection";
import { LandingDetailsSection } from "./LandingDetailsSection";
import { LandingLoadsExamples } from "./LandingLoadsExamples";
import { LandingTryNow } from "./LandingTryNow";

function Landing(props) {
  return (
    <>
      <LandingTopSection />
      <LandingHowToSection />
      <LandingLoadsExamples />
      <LandingDetailsSection />
      <LandingTryNow />
    </>
  );
}

export default Landing;
