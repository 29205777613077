import "./index.css";

import { hydrateRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App.jsx";

const user = window.__SSR_USER__;
const build = window.__SSR_BUILD__;
const instance = window.__SSR_INSTANCE__;
const os = window.__SSR_OS__;

delete window.__SSR_USER__;
delete window.__SSR_BUILD__;
delete window.__SSR_INSTANCE__;
delete window.__SSR_OS__;

hydrateRoot(
  document.getElementById("root"),
  <BrowserRouter>
    <App user={user} build={build} instance={instance} os={os} />
  </BrowserRouter>,
);
