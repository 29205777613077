import React from "react";
import { Link } from "react-router-dom";
import { cn } from "@/lib/utils";

import LogoIcon from "@/assets/logo-icon.svg";
import LogoFull from "@/assets/logo-full-app.svg";
import LogoFullWhite from "@/assets/logo-full-app-white.svg";

export default function NavLogoApp({ className, to }) {
  return (
    <Link to={to ?? "/app/home"} className={cn("hidden flex-row md:flex", className)}>
      <img src={LogoIcon} alt="logo" className="flex h-6 p-0 md:hidden md:h-8" />
      <img src={LogoFull} alt="logo" className="hidden h-8 p-0 md:flex dark:md:hidden" />
      <img src={LogoFullWhite} alt="logo" className="hidden h-8 p-0 md:hidden dark:md:flex" />
    </Link>
  );
}
