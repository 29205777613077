import { Divlr } from "@/components/animations";
import { cn } from "@/lib/utils";
import { Info, Rss, TriangleAlert } from "lucide-react";
import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";

const WarningAction = lazy(() => import("./WarningAction"));

export default function WarningBar({ label, level, action }) {
  const user = useSelector((state) => state.user);
  const pm = user?.session?.profile?.permissionMatrix;

  return (
    <div
      className={cn(
        "flex h-10 flex-row items-center space-x-2 pl-6 pr-4 text-[14px] font-semibold",
        level === "error"
          ? "bg-red-100 text-red-700 dark:bg-red-600 dark:bg-opacity-40 dark:text-gray-100"
          : level === "warning"
            ? "bg-orange-100 text-orange-500 dark:bg-orange-600 dark:bg-opacity-40 dark:text-gray-100"
            : "bg-blue-100 text-blue-700 dark:bg-blue-600 dark:bg-opacity-40 dark:text-gray-100",
      )}
    >
      <Divlr className="flex w-2/3 flex-row items-center md:w-fit">
        {level === "error" ? <TriangleAlert size={19} /> : level === "warning" ? <Rss size={19} /> : <Info size={19} />}
        <div className="truncate whitespace-nowrap pl-2 pr-3">{label}</div>
      </Divlr>
      <div className="flex w-full justify-end md:w-fit">
        <Suspense>
          <WarningAction action={action} />
        </Suspense>
      </div>
    </div>
  );
}
