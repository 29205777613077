import { lazy, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { X } from "lucide-react";
import { cn } from "@/lib/utils";

import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { closeDialog } from "@/stores/navSlice";
import { Dialog, DialogContent, DialogDescription, DialogTitle } from "@/components/ui/dialog";

const StorageFilePreview = lazy(() => import("./app/file/StorageFilePreview"));
const BucketFilePreview = lazy(() => import("./app/file/BucketFilePreview"));

const registry = {
  "dialog::file-preview": {
    Component: StorageFilePreview,
    classes: "min-w-[100%] md:min-w-[650px] lg:min-w-[1000px] bg-white dark:bg-[#202126]",
    view: "sheet",
  },
  "dialog::file": {
    Component: BucketFilePreview,
    classes: "min-w-[800px] min-h-[85vh]",
    view: "dialog",
  },
};

export default function Overlay() {
  const dispatch = useDispatch();

  const dialogVisible = useSelector((state) => state.nav.dialogVisible);
  const dialogParams = useSelector((state) => state.nav.dialogParams);

  const contailer = registry[dialogParams?.type];

  useEffect(() => {
    if (!contailer && dialogVisible) {
      dispatch(closeDialog());
    }
  }, [contailer]);

  if (!contailer) {
    return;
  }

  return (
    <div className="absolute">
      {contailer.view === "sheet" ? (
        <Sheet
          open={dialogVisible}
          onOpenChange={(open) => {
            if (!open) {
              dispatch(closeDialog());
            }
          }}
        >
          <SheetContent
            className="min-w-[90%] p-0 md:min-w-min"
            closeIcon={<X size={20} className="mt-[1px] dark:text-gray-200" />}
          >
            <SheetHeader>
              <SheetTitle className="hidden" />
              <SheetDescription className="hidden" />
            </SheetHeader>

            <div className={cn(contailer.classes, "h-full")}>
              <Suspense>
                <contailer.Component {...(dialogParams.params ?? {})} variant="overlay" overlay={dialogParams?.type} />
              </Suspense>
            </div>
          </SheetContent>
        </Sheet>
      ) : contailer.view === "dialog" ? (
        <Dialog
          open={dialogVisible}
          onOpenChange={(open) => {
            if (!open) {
              dispatch(closeDialog());
            }
          }}
        >
          <DialogContent
            className={cn(contailer.classes, "p-0")}
            closeIcon={<X size={20} className="mt-[1px] dark:text-gray-200" />}
          >
            <DialogTitle className="hidden h-0" />
            <DialogDescription className="hidden h-0" />
            <div className={cn("flex h-full w-full items-center justify-center")}>
              <Suspense>
                <contailer.Component {...(dialogParams.params ?? {})} variant="overlay" overlay={dialogParams?.type} />
              </Suspense>
            </div>
          </DialogContent>
        </Dialog>
      ) : null}
    </div>
  );
}
