"use client";
import { cn } from "@/lib/utils";
import React from "react";

const container =
  "relative flex flex-col h-[90vh] items-center justify-center bg-zinc-100 dark:bg-black/20 text-slate-950 transition-bg";

const content = `
[--white-gradient:repeating-linear-gradient(100deg,var(--white)_0%,var(--white)_7%,var(--transparent)_10%,var(--transparent)_12%,var(--white)_16%)]
[--dark-gradient:repeating-linear-gradient(100deg,var(--black)_0%,var(--black)_7%,var(--transparent)_10%,var(--transparent)_12%,var(--black)_16%)]
[--aurora:repeating-linear-gradient(100deg,var(--blue-600)_10%,var(--indigo-400)_15%,var(--pink-100)_20%,var(--violet-300)_25%,var(--purple-400)_30%)]
[background-image:var(--white-gradient),var(--aurora)]
dark:[background-image:var(--dark-gradient),var(--aurora)]
[background-size:300%,_200%]
[background-position:50%_50%,50%_50%]
filter blur-[10px] invert dark:invert-0
after:content-[""] after:absolute after:inset-0 after:[background-image:var(--white-gradient),var(--aurora)] 
after:dark:[background-image:var(--dark-gradient),var(--aurora)]
after:[background-size:200%,_100%] 
after:animate-aurora after:[background-attachment:fixed] after:mix-blend-difference
pointer-events-none
absolute -inset-[10px] opacity-50 will-change-transform dark:opacity-60`;

const radial = `[mask-image:radial-gradient(ellipse_at_100%_0%,black_10%,var(--transparent)_70%)]`;
const radialExt = `[mask-image:radial-gradient(ellipse_at_100%_0%,black_10%,var(--transparent)_100%)]`;

export const AuroraBackground = ({
  className,
  staticBackground,
  children,
  variant,
  showRadialGradient = true,
  ...props
}) => {
  return (
    <div className={cn(container, className)} {...props}>
      <div className="absolute inset-0 overflow-hidden">
        {staticBackground && (
          <img
            className={cn("h-full w-full object-fill transition duration-500")}
            src={staticBackground}
            alt={"background"}
          />
        )}
        <div
          //   I'm sorry but this is what peak developer performance looks like // trigger warning
          className={cn(
            !!staticBackground ? null : content,
            showRadialGradient && (variant === "ext" ? radialExt : radial),
          )}
        ></div>
      </div>
      {children}
    </div>
  );
};
