import { createSlice } from "@reduxjs/toolkit";

export const confingSlice = createSlice({
  name: "config",
  initialState: {
    selectedCampaign: null,
  },
  reducers: {
    setSelectedCampaign: (state, action) => {
      state.selectedCampaign = action.payload;
    },
    clearConfig: (state) => {
      state.selectedCampaign = null;
    },
  },
});

export const { setSelectedCampaign, clearConfig } = confingSlice.actions;

export default confingSlice.reducer;
