import axios from "axios";
import { Moon, Sun } from "lucide-react";
import { Switch } from "@/components/ui/switch";

import { setTheme } from "@/stores/themeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";

export default function ThemeSwitcher({}) {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.current);
  const user = useSelector((state) => state.user);
  const initialized = useRef();

  const mutation = useMutation({
    fetchPolicy: "no-cache",
    mutationFn: (data) => {
      return axios.put(`/users/${user?.session?.user.id}`, data);
    },
  });

  function onSubmit(theme) {
    mutation.mutate(
      { data: { theme } },
      {
        onSuccess: () => {
          console.log("[ThemeSwitcher] saved theme");
        },
        onError: (res) => {
          console.error("[ThemeSwitcher] failed to save", res);
        },
      },
    );
  }

  useEffect(() => {
    setTimeout(() => {
      initialized.current = true;
    }, 500);
  }, []);

  return (
    <Switch
      checked={theme === "dark"}
      icon={theme === "dark" ? <Moon className="text-neutral-400" size={11} /> : <Sun size={11} />}
      className={initialized.current ? "" : "transition-none"}
      classes={{ thumb: initialized.current ? "" : "transition-none" }}
      onCheckedChange={() => {
        const newTheme = theme === "dark" ? "light" : "dark";
        onSubmit(newTheme);
        dispatch(setTheme(newTheme));
      }}
    />
  );
}
