import { useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Functions
import { cn, headerWarning } from "@/lib/utils";
import { useAppLocation } from "@/hooks/navigation";
import { triggerSidebar } from "@/stores/navSlice";

// Components
import {
  AtSign,
  BookText,
  Box,
  Braces,
  CircleAlert,
  CirclePlay,
  Clock8,
  CreditCard,
  Database,
  Files,
  HouseIcon,
  LineChart,
  MailPlus,
  Mails,
  MonitorCogIcon,
  Settings,
  Shield,
  SquareGanttChart,
  Truck,
  Users,
} from "lucide-react";
import { useMemo } from "react";

export function Item({ type, icon, index, label, badge, warning, to, onClick, entrance, pathname }) {
  const dispatch = useDispatch();

  if (type === "section") {
    return (
      <div
        index={index}
        className={cn(
          "flex w-full flex-col whitespace-nowrap rounded-lg px-4 pb-1.5 pt-5 text-[15px] font-bold tracking-[-0.015rem] text-slate-800 dark:text-slate-300 md:text-[14px] 2xl:text-[15px]",
        )}
      >
        <p>{label}</p>
      </div>
    );
  }

  return (
    <div
      index={index}
      className={cn(
        "flex w-full flex-col rounded-lg transition-colors hover:bg-gray-400/20",
        pathname === to
          ? "bg-gray-400/30 text-black hover:bg-gray-400/30 dark:text-slate-300"
          : entrance?.length && entrance === to
            ? "bg-gray-400/20 duration-500 dark:bg-gray-400/10 dark:text-slate-300"
            : "dark:text-slate-400",
      )}
    >
      {onClick ? (
        <div
          className="flex min-w-32 cursor-pointer flex-row items-center space-x-3 whitespace-nowrap px-4 py-2 text-[14px] md:text-[13px] 2xl:text-[14px]"
          onClick={() => onClick?.(to)}
        >
          {icon}
          <p>{label}</p>
        </div>
      ) : (
        <Link
          className="flex min-w-32 flex-row items-center space-x-3 whitespace-nowrap px-4 py-2 text-[14px] md:text-[13px] 2xl:text-[14px]"
          to={to}
          onClick={() => {
            window.scrollTo(0, 0);
            dispatch(triggerSidebar());
          }}
        >
          <div className="min-w-4"> {icon}</div>
          <p className="flex w-full">{label}</p>
          {warning ? (
            <CircleAlert className="text min-w-4 text-red-600" size={16} strokeWidth={"2px"} />
          ) : badge > 0 ? (
            <p className="flex min-h-[20px] w-[20px] min-w-[20px] items-center justify-center rounded-full bg-black p-0 text-xs font-semibold text-white dark:bg-neutral-200 dark:text-neutral-800">
              {badge}
            </p>
          ) : null}
        </Link>
      )}
    </div>
  );
}

export default function Sidebar() {
  const location = useLocation();

  const user = useSelector((state) => state.user);
  const nav = useSelector((state) => state.nav);

  const entrance = nav?.breadcrumbs?.[0]?.path;

  const { isApp } = useAppLocation();
  const warningBar = useMemo(() => (isApp ? headerWarning(user, true) : null), [isApp, user]);

  if (!isApp) return;

  const account = user?.session?.account;
  const profile = user?.session?.profile;
  const permissions = profile?.permissionMatrix;
  const system = account?.variant === "system";

  const onboardingBadge =
    !system &&
    (account?.onboarding === "not_completed" || profile?.pluginOnboarding === "not_completed") &&
    profile?.accountOwner
      ? 1
      : 0;

  const menuItems = [
    { type: "section", label: "Platform" },
    { icon: HouseIcon, to: "/app/home", label: "Home", badge: onboardingBadge },
  ];
  if (system) {
    menuItems.push({ icon: BookText, to: "/app/accounts", label: "Accounts" });
    menuItems.push({ icon: Box, to: "/app/fleets", label: "Fleets" });
    menuItems.push({ icon: Truck, to: "/app/trucks", label: "Trucks" });
    menuItems.push({ icon: Users, to: "/app/users", label: "Users" });
    menuItems.push({ icon: Files, to: "/app/files", label: "Files" });
    menuItems.push({ icon: Settings, to: "/app/settings", label: "Settings" });
    menuItems.push({ type: "section", label: "CRM" });
    menuItems.push({ icon: MailPlus, to: "/app/services", label: "Services" });
    menuItems.push({ icon: LineChart, to: "/app/crm/analytics", label: "Analytics" });
    menuItems.push({ icon: Mails, to: "/app/crm/contacts", label: "Contacts" });
    menuItems.push({ icon: AtSign, to: "/app/crm/emails", label: "Emails" });
    menuItems.push({ type: "section", label: "Cron" });
    menuItems.push({ icon: Clock8, to: "/app/cron/jobs", label: "Jobs" });
    menuItems.push({ icon: CirclePlay, to: "/app/cron/runs", label: "Runs" });
    menuItems.push({ type: "section", label: "Internal" });
    menuItems.push({ icon: Database, to: "/app/bucket/files", label: "Bucket" });
    menuItems.push({ icon: SquareGanttChart, to: "/app/plans", label: "Plans" });
    menuItems.push({ icon: CreditCard, to: "/app/subscriptions", label: "Subscriptions" });
    if (process.env.NODE_ENV === "development") {
      menuItems.push({ icon: MonitorCogIcon, to: "/app/system", label: "System" });
    }
  } else {
    if (permissions?.fleets > 0 && (profile?.dataAccessLevel === "full" || user?.session?.accessFleetIds?.length > 0))
      menuItems.push({ icon: Box, to: "/app/fleets", label: "Fleets" });
    if (permissions?.trucks > 0) menuItems.push({ icon: Truck, to: "/app/trucks", label: "Trucks" });
    if (permissions?.users > 0) menuItems.push({ icon: Users, to: "/app/users", label: "Users" });
    menuItems.push({ icon: Settings, to: "/app/settings", label: "Settings" });

    menuItems.push({ type: "section", label: "Load boards" });
    menuItems.push({ icon: Braces, to: "/app/templates", label: "Templates" });
    menuItems.push({ icon: MailPlus, to: "/app/services", label: "Emails" });
    menuItems.push({ icon: Shield, to: "/app/fraudcontacts", label: "Fraud" });
  }

  return (
    <div
      className={cn(
        "fixed bottom-0 left-0 top-0 z-30 overflow-y-scroll border border-b-0 border-l-0 border-r bg-white dark:border-r-black dark:bg-[#202126]",
        warningBar ? "mt-24" : "mt-14",
      )}
    >
      <div
        className={cn(
          "flex flex-col rounded-none pt-1 transition-all xl:transition-none",
          nav.sidebarVisible ? "w-screen md:w-[15rem] 2xl:w-[17rem]" : "w-[0rem] xl:w-[15rem] 2xl:w-[17rem]",
        )}
      >
        <div className={cn("flex w-full flex-col items-center space-y-1.5 px-3 pb-3 md:space-y-1")}>
          {menuItems.map((item, i) => (
            <Item
              key={i}
              icon={item.icon ? <item.icon size={16} /> : null}
              pathname={location.pathname}
              entrance={entrance}
              type={item.type}
              label={item.label}
              badge={item.badge}
              warning={item.warning}
              to={item.to}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
