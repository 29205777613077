// Functions
import { cn } from "@/lib/utils";
import { useAppLocation } from "@/hooks/navigation";

// Components
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Footer() {
  const user = useSelector((state) => state.user);

  const { isAuth, isApp, isDocs } = useAppLocation();

  if (isAuth || isApp || isDocs) {
    return null;
  }

  return (
    <footer className={cn("relative flex w-full flex-col overflow-x-clip")}>
      <div
        className={cn(
          "flex flex-col items-center space-y-8 text-nowrap border-t border-gray-100 bg-gray-100 py-10 dark:border-black dark:bg-[#202126] md:space-y-12 md:py-10 lg:flex-row lg:space-y-0",
        )}
      >
        <div
          className={cn(
            "flex w-full flex-col space-x-0 space-y-4 px-8 text-gray-800 dark:text-gray-400 lg:flex-row lg:space-x-10 lg:space-y-0 lg:px-24 xl:px-32 2xl:px-44",
          )}
        >
          <div className="hidden h-28 w-[1px] bg-gray-400 opacity-30 dark:bg-gray-200 lg:flex" />
          <div className="flex flex-col">
            <p className="text-md pb-2 font-serious font-black text-gray-800 dark:text-gray-200">Links</p>
            <ul className="list-disc space-y-1 pl-4">
              <li>
                <Link to="/pricing" className="text-sm underline-offset-2 hover:underline">
                  Pricing
                </Link>
              </li>
              <li>
                <Link to="/contact?target=support" className="text-sm underline-offset-2 hover:underline">
                  Support
                </Link>
              </li>
              <li>
                <Link to={"/docs"} className="text-sm underline-offset-2 hover:underline">
                  Q&A
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="hidden w-full flex-col items-center space-y-2 text-sm font-light text-gray-600 dark:text-gray-500 lg:flex">
          <div className="flex w-full flex-row items-center justify-center space-x-2 pr-14 lg:pr-14">
            <Link to="/docs/policies/privacy" className="underline-offset-2 hover:underline">
              Privacy Policy
            </Link>
            <div className="h-1 w-1 rounded-full bg-gray-400 dark:bg-gray-700" />
            <Link to="/docs/policies/terms" className="underline-offset-2 hover:underline">
              Terms of Use
            </Link>
          </div>
          <div className="mr-11 flex w-full flex-row items-center justify-center space-x-2">
            <p>©2025</p>
            <p>Ten4 Intelligence Inc.</p>
            <p>All rights reserved.</p>
          </div>
          <p className="mr-10 whitespace-break-spaces">1000 Brickell Avenue, Suite #715, Miami, FL 33131</p>
        </div>
        <div className="flex w-full flex-col space-y-2 overflow-clip px-8 text-sm font-light text-gray-600 lg:hidden">
          <div className="flex w-full flex-row items-center space-x-2 pr-14 lg:pr-14">
            <Link to="/docs/policies/privacy" className="underline-offset-2 hover:underline">
              Privacy Policy
            </Link>
            <div className="h-1 w-1 rounded-full bg-gray-400 dark:bg-gray-700" />
            <Link to="/docs/policies/terms" className="underline-offset-2 hover:underline">
              Terms of Use
            </Link>
          </div>
          <p className="whitespace-break-spaces">©2025 Ten4 Intelligence Inc. All rights reserved.</p>
          <p className="whitespace-break-spaces pt-3">1000 Brickell Ave, Suite #715</p>
          <p className="whitespace-break-spaces">Miami, FL 33131</p>
        </div>
      </div>
    </footer>
  );
}
