import { lazy, useEffect, useMemo } from "react";
import { Route, Routes } from "react-router-dom";

// Functions
import { cn, headerWarning } from "@/lib/utils";
import { useAppLocation } from "@/hooks/navigation";

// Navigation
import { LazyWave, LazyEmpty, ScrollToTop, PrivateOnly, PublicOnly } from "@/lib/navigation";
import { Toaster } from "@/components/ui/sonner";

// Static pages
import Landing from "./static/landing/Landing";
import PageNotFound from "./static/system/PageNotFound";
import RedirectProgress from "./static/system/RedirectProgress";

const ContactUs = lazy(() => import("./static/landing/ContactUs"));
const Pricing = lazy(() => import("./static/landing/Pricing"));
const Version = lazy(() => import("./static/landing/Version"));

import DocMain from "./static/docs/DocMain";
import { useSelector } from "react-redux";
import { recordUtm } from "@/lib/storage";

// Auth pages
const SigninWithOAuth = lazy(() => import("./auth/SigninWithOAuth"));
const SigninWithEmail = lazy(() => import("./auth/SigninWithEmail"));
const Signup = lazy(() => import("./auth/Signup"));
const Recovery = lazy(() => import("./auth/Recovery"));

// App pages
const Dashboard = lazy(() => import("./app/home/Dashboard"));
const UserDetails = lazy(() => import("./app/users/UserDetails"));
const AccountDetails = lazy(() => import("./app/account/AccountDetails"));
const FleetDetails = lazy(() => import("./app/truck/FleetDetails"));
const TrailerDetails = lazy(() => import("./app/truck/TrailerDetails"));
const TruckDetails = lazy(() => import("./app/truck/TruckDetails"));

// System only
const Accounts = lazy(() => import("./app/account/Accounts"));
const Fleets = lazy(() => import("./app/fleet/Fleets"));
const Trucks = lazy(() => import("./app/truck/Trucks"));
const Users = lazy(() => import("./app/users/Users"));
const Templates = lazy(() => import("./app/template/Templates"));
const FraudContacts = lazy(() => import("./app/fraud/FraudContacts"));
const Services = lazy(() => import("./app/template/Services"));
const SystemInfo = lazy(() => import("./app/system/SystemInfo"));
const Plans = lazy(() => import("./app/plans/Plans"));
const PlanDetails = lazy(() => import("./app/plans/PlanDetails"));
const Subscriptions = lazy(() => import("./app/subscription/Subscriptions"));
const SubscriptionDetails = lazy(() => import("./app/subscription/SubscriptionDetails"));
const SubscriptionConfirmation = lazy(() => import("./app/subscription/SubscriptionConfirmation"));
const Settings = lazy(() => import("./app/settings/Settings"));
const BucketFiles = lazy(() => import("./app/file/BucketFiles"));
const StorageFiles = lazy(() => import("./app/file/StorageFiles"));

// CRM
const CrmAnalytics = lazy(() => import("./app/crm/CrmAnalytics"));
const CrmContacts = lazy(() => import("./app/crm/CrmContacts"));
const CrmEmailsSystem = lazy(() => import("./app/crm/CrmEmailsSystem"));
const CrmContactDetails = lazy(() => import("./app/crm/CrmContactDetails"));

// Cron
const CronJobs = lazy(() => import("./app/crons/CronJobs"));
const CronJobDetails = lazy(() => import("./app/crons/CronJobDetails"));
const CronRuns = lazy(() => import("./app/crons/CronRuns"));

export default function Main() {
  const { isApp } = useAppLocation();
  const user = useSelector((state) => state.user);
  const warningBar = useMemo(() => (isApp ? headerWarning(user, true) : null), [isApp, user]);

  useEffect(() => {
    // No need to record visit even if already logged in
    if (user?.session?.profile?.id) return;

    // Record visit even if already logged in
    (async () => {
      try {
        await recordUtm();
      } catch (e) {
        console.log("[Ten4] failed to record visit", e.message);
      }
    })();
  }, [user]);

  return (
    <>
      <main
        className={cn(
          "overflow-x-clip text-black dark:text-gray-400",
          isApp ? "h-[86vh] pl-0 md:h-screen xl:pl-[15rem] 2xl:pl-[17rem]" : "",
          isApp ? (warningBar ? "pt-24" : "pt-14") : "",
        )}
      >
        <Routes>
          <Route index element={<ScrollToTop element={<Landing />} />} />
          <Route path="/plugin" element={<ScrollToTop element={<Landing />} />} />

          <Route path="/" element={<PublicOnly />}>
            <Route path="signin" element={<LazyWave Component={SigninWithOAuth} />} />
            <Route path="signin/email" element={<LazyWave Component={SigninWithEmail} />} />
            <Route path="signin/recover" element={<LazyWave Component={Recovery} />} />
            <Route path="signup" element={<LazyWave Component={Signup} />} />
            <Route path="recover-password" element={<LazyWave Component={Recovery} />} />
          </Route>

          <Route path="/docs/*" element={<DocMain />} />

          <Route path="/pricing" element={<LazyEmpty element={<ScrollToTop element={<Pricing />} />} />} />
          <Route path="/contact" element={<LazyEmpty element={<ScrollToTop element={<ContactUs />} />} />} />
          <Route path="/version" element={<LazyEmpty element={<ScrollToTop element={<Version />} />} />} />
          <Route path="/postmessage" element={<RedirectProgress />} />

          <Route path="/app" element={<PrivateOnly />}>
            <Route index element={<LazyEmpty Component={Dashboard} />} />
            <Route path="home" element={<LazyEmpty Component={Dashboard} />} />
            <Route path="users" element={<LazyEmpty Component={Users} />} />
            <Route path="users/:id" element={<LazyEmpty Component={UserDetails} />} />
            <Route path="accounts" element={<LazyEmpty Component={Accounts} />} />
            <Route path="accounts/:id" element={<LazyEmpty Component={AccountDetails} />} />
            <Route path="fleets" element={<LazyEmpty Component={Fleets} />} />
            <Route path="fleets/:id" element={<LazyEmpty Component={FleetDetails} />} />
            <Route path="trailers/:id" element={<LazyEmpty Component={TrailerDetails} />} />
            <Route path="trucks" element={<LazyEmpty Component={Trucks} />} />
            <Route path="trucks/:id" element={<LazyEmpty Component={TruckDetails} />} />
            <Route path="system" element={<LazyEmpty Component={SystemInfo} />} />
            <Route path="settings" element={<LazyEmpty Component={Settings} />} />
            <Route path="files" element={<LazyEmpty Component={StorageFiles} />} />
            <Route path="templates" element={<LazyEmpty Component={Templates} />} />
            <Route path="fraudcontacts" element={<LazyEmpty Component={FraudContacts} />} />
            <Route path="plans" element={<LazyEmpty Component={Plans} />} />
            <Route path="plans/:id" element={<LazyEmpty Component={PlanDetails} />} />
            <Route path="subscriptions" element={<LazyEmpty Component={Subscriptions} />} />
            <Route path="subscriptions/:id" element={<LazyEmpty Component={SubscriptionDetails} />} />
            <Route path="subscription/confirmation" element={<LazyEmpty Component={SubscriptionConfirmation} />} />
            <Route path="services" element={<LazyEmpty Component={Services} />} />
            <Route path="crm/analytics" element={<LazyEmpty Component={CrmAnalytics} />} />
            <Route path="crm/contacts" element={<LazyEmpty Component={CrmContacts} />} />
            <Route path="crm/emails" element={<LazyEmpty Component={CrmEmailsSystem} />} />
            <Route path="crm/contacts/:id" element={<LazyEmpty Component={CrmContactDetails} />} />
            <Route path="cron/jobs" element={<LazyEmpty Component={CronJobs} />} />
            <Route path="cron/jobs/:id" element={<LazyEmpty Component={CronJobDetails} />} />
            <Route path="cron/runs" element={<LazyEmpty Component={CronRuns} />} />
            <Route path="bucket/files" element={<LazyEmpty Component={BucketFiles} />} />
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </main>
      <Toaster />
    </>
  );
}
