import { Divlr } from "@/components/animations";
import React, { useEffect } from "react";

function DocContainer({ children }) {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  return <Divlr className="max-w-[800px]">{children}</Divlr>;
}

export default DocContainer;
