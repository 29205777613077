import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export function LandingTryNow() {
  const user = useSelector((state) => state.user);

  if (!!user?.token) {
    return null;
  }

  return (
    <div className="">
      <div className="flex w-full flex-col items-center space-y-4 bg-indigo-100 px-6 py-6 lg:flex-row lg:space-y-0 lg:px-20 lg:py-4 xl:px-24 2xl:px-48">
        <div className="flex w-full flex-col lg:w-2/3">
          <div className="font-serious text-xl font-extrabold text-indigo-800">
            Supercharge your favorite load boards today
          </div>
          <div className="text-md font-light text-indigo-600">Real freel trial, no credit card required!</div>
        </div>
        <div className="flex w-full items-center justify-start space-x-4 lg:w-1/3 lg:justify-center">
          <Button className="bg-indigo-600 p-0 text-base hover:bg-indigo-700 dark:bg-indigo-600 dark:text-white hover:dark:bg-indigo-700">
            <Link className="px-5 py-2" to={"/signup"}>
              Try For Free
            </Link>
          </Button>
          <Button variant="outline" className="border-indigo-700 bg-transparent text-base text-indigo-700">
            <Link to={"/contact?target=sales&reason=demo"}>Request Demo</Link>
          </Button>
        </div>
      </div>
    </div>
  );
}
