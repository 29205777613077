import { createSlice } from "@reduxjs/toolkit";

export const systemSlice = createSlice({
  name: "user",
  initialState: {
    build: null,
    instance: null,
  },
});

export default systemSlice.reducer;
