import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

// Functions
import { clearUser } from "@/stores/userSlice";
import { clearConfig } from "@/stores/configSlice";

// Componentns
import { Bug, Hourglass, Unplug } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { AuroraBackground } from "@/components/animations/aurora-background";
import { useEffect, useState } from "react";
import { clearStorageSafe } from "@/lib/storage";

function AuthError() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies(null, { path: "/" });

  const user = useSelector((state) => state.user);
  const isInvalidSession = user.error?.message === "unathorized access";
  const isConnectionError = user.error?.message?.includes("ECONNREFUSED");

  const [logoutAs, setLogoutAs] = useState();
  useEffect(() => {
    const token = localStorage.getItem("signout_token");
    const url = localStorage.getItem("signout_url");
    if (token?.length > 0) {
      setLogoutAs({ token, url });
    }
  }, []);

  return (
    <AuroraBackground className={"h-screen p-0"}>
      <div className="relative h-screen w-full items-center justify-center">
        <div className="flex h-screen items-center justify-center px-4 pb-20 md:space-x-20">
          <div className="hidden h-[70vh] w-[1px] bg-slate-300 opacity-30 md:flex" />
          <div className="flex w-full flex-col rounded-lg border-[1px] border-slate-200 bg-white shadow-2xl md:w-[30rem]">
            <div index={3} className="flex flex-row items-center space-x-8 px-8 py-6">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    {isConnectionError ? (
                      <Unplug size={36} />
                    ) : isInvalidSession ? (
                      <Hourglass size={36} />
                    ) : (
                      <Bug size={36} />
                    )}
                  </TooltipTrigger>
                  <TooltipContent>
                    <p className="text-slate-300">Message: {user.error?.message ?? "No Message"}</p>
                    {user.error?.reason && (
                      <p className="text-slate-300">Reason: {user.error?.reason ?? "No provided"}</p>
                    )}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>

              <div className="flex flex-col space-y-1">
                <p className="text-base">{"Oops..."}</p>
                <p className="text-base">
                  {isConnectionError
                    ? "Service temporary unavailable, please try again in few minutes"
                    : isInvalidSession
                      ? "Your session has expired"
                      : "Something went wrong, please try again"}
                </p>
              </div>
            </div>
            <div className="-mt-5 space-y-3 p-5">
              {!isInvalidSession && (
                <Button
                  className="flex w-full p-0 font-medium"
                  variant="outline"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Retry
                </Button>
              )}
              <Button
                className="flex w-full p-0 font-medium"
                onClick={() => {
                  clearStorageSafe();
                  cookies.remove("104jwc", { path: "/" });
                  dispatch(clearUser());
                  dispatch(clearConfig());
                  navigate("/");
                }}
              >
                Clear Session
              </Button>
              {logoutAs && (
                <Button
                  variant="destructive"
                  className="flex w-full p-0 font-medium"
                  onClick={() => {
                    clearStorageSafe();
                    var expires = new Date();
                    expires.setDate(expires.getDate() + 30);
                    cookies.set("104jwc", logoutAs.token, { expires });
                    window.location.replace(logoutAs.url);
                  }}
                >
                  Stop Impersonation
                </Button>
              )}
            </div>
          </div>
          <div className="hidden h-[70vh] w-[1px] bg-slate-300 opacity-30 md:flex" />
        </div>
      </div>
    </AuroraBackground>
  );
}

export default AuthError;
