import { motion } from "framer-motion";
import { Check, Loader, Route, SendHorizonal } from "lucide-react";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { Divlr } from "@/components/animations";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

// Icons

const Checkbox = () => {
  return (
    <div className="min-w-6 max-w-6 xl:min-w-7 xl:max-w-7">
      <svg
        width="100%"
        height="100%"
        fill="none"
        viewBox="0 0 16 17"
        fit=""
        preserveAspectRatio="xMidYMid meet"
        focusable="false"
      >
        <path fill="#0046E0" d="M7.05 10.038L5.333 8.294h-2l3.955 4 5.379-8.667h-1.639l-3.978 6.41z"></path>
      </svg>
    </div>
  );
};

const Arrow = () => {
  return (
    <div className="min-w-5 max-w-5 xl:min-w-6 xl:max-w-6">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 22 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        fit=""
        preserveAspectRatio="xMidYMid meet"
        focusable="false"
      >
        <path
          d="M3 7.28571C3.71008 7.28571 4.28571 6.71008 4.28571 6C4.28571 5.28992 3.71008 4.71429 3 4.71429C2.28992 4.71429 1.71429 5.28992 1.71429 6C1.71429 6.71008 2.28992 7.28571 3 7.28571ZM3 9C4.65685 9 6 7.65685 6 6C6 4.34315 4.65685 3 3 3C1.34315 3 0 4.34315 0 6C0 7.65685 1.34315 9 3 9Z"
          fill="#0046E0"
        ></path>
        <g>
          <path d="M15.5266 0L14 1.415L18.9467 6L14 10.585L15.5266 12L22 6L15.5266 0Z" fill="#0046E0"></path>
        </g>
        <rect x="8" y="5" width="2" height="2" fill="#0046E0"></rect>
        <rect x="12" y="5" width="2" height="2" fill="#0046E0"></rect>
        <defs>
          <clipPath id="clip0_269_43946">
            <rect width="8" height="12" fill="white" transform="translate(14)"></rect>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

// Componets

export function Tab({ label, tab, activeTab, setActiveTab }) {
  return (
    <div
      className={cn(
        "flex min-w-36 cursor-pointer justify-center whitespace-nowrap px-3.5 py-1.5 font-serious text-[18px] uppercase tracking-tight",
        tab === activeTab
          ? "border-b-4 border-black font-semibold text-black"
          : "border-b border-gray-300 font-semibold text-gray-400 dark:border-gray-400",
      )}
      onClick={() => setActiveTab(tab)}
    >
      {label}
    </div>
  );
}

const Separator = ({ className, small }) => {
  return (
    <div className={cn("mx-3 h-8 min-w-[1px] max-w-[1px] bg-gray-300 2xl:mx-4", className, small ? "mx-2.5" : "")} />
  );
};

const SeparatorSmall = ({ className }) => {
  return <div className={cn("mx-1 h-3 min-w-[1px] max-w-[1px] bg-gray-300", className)} />;
};

const Breakdown = ({ children, data }) => {
  return (
    <Popover>
      <PopoverTrigger>
        <div>{children}</div>
      </PopoverTrigger>
      <PopoverContent className="w-48 bg-blue-600 p-3.5 text-[12px] font-semibold text-white opacity-90">
        <div className="flex flex-col space-y-1.5">
          {data.rate && (
            <div className="flex flex-row justify-between">
              <div>RATE</div>
              <div>$ {data.rate}</div>
            </div>
          )}
          <div className="flex flex-row justify-between">
            <div>DH-O</div>
            <div>{data.dho} mi</div>
          </div>
          <div className="flex flex-row justify-between">
            <div>MILES</div>
            <div>{data.miles} mi</div>
          </div>
          <div className="flex flex-row justify-between">
            <div>FUEL</div>
            <div>{data.gallon} / gal</div>
          </div>
          <div className="flex flex-row justify-between">
            <div>DURATION</div>
            <div>{data.duration} day(s)</div>
          </div>
          <div className="flex flex-row justify-between border-t border-white pt-1">
            <div>FEES</div>
            <div>$ {data.fees}</div>
          </div>
          <div className="flex flex-row justify-between">
            <div>FUEL</div>
            <div>$ {data.fuel}</div>
          </div>
          <div className="flex flex-row justify-between">
            <div>TRUCK</div>
            <div>$ {data.truck}</div>
          </div>
          <div className="flex flex-row justify-between">
            <div>TRAILER</div>
            <div>$ {data.trailer}</div>
          </div>
          <div className="flex flex-row justify-between">
            <div>DRIVER</div>
            <div>$ {data.driver}</div>
          </div>
          <div className="flex flex-row justify-between border-t border-white pt-1.5">
            <div>EXPENSES</div>
            <div>$ {data.expenses}</div>
          </div>
          {data.profit > 0 && (
            <div className="flex flex-row justify-between border-t border-white pt-1.5">
              <div>PROFIT</div>
              <div>$ {data.profit}</div>
            </div>
          )}
          {data.losses < 0 && (
            <div className="flex flex-row justify-between border-t border-white pt-1.5">
              <div>LOSSES</div>
              <div>$ {data.losses}</div>
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};

// Rows

export function DATRow({
  className,
  part,
  min,
  rate,
  amount,
  amoutVariant,
  amountBreakdown,
  loadedMiles,
  emptyMiles,
  origin,
  destination,
  destinationVariant,
  date,
  equipmentShort,
  equipmentFull,
  length,
  lengthVariant,
  weight,
  weightVariant,
  size,
  broker,
  brokerVariant,
  email,
  emailVariant,
  phone,
  cs,
  dtp,
  index,
  animatePriceClick,
  animateSendClick,
}) {
  const [sendingState, setSendingState] = useState();

  return (
    <div
      index={index * 0.3}
      className={cn(
        "flex min-h-[60px] w-full flex-row items-center whitespace-nowrap py-2.5 font-serious text-[13px] font-bold lg:text-[12px] 2xl:text-[13px]",
        className,
      )}
    >
      {(!part || part === "rate") && (
        <>
          <div className="flex min-w-16 items-center justify-center space-x-2 pl-3 lg:min-w-14 xl:min-w-20 xl:space-x-3 xl:pl-4">
            <div className="h-3 w-3 border-2 border-gray-500 xl:h-4 xl:w-4" />
            <p>{min > 60 ? parseInt(min / 60) + "h" : min + "m"}</p>
          </div>

          <Separator />
          <div className="flex min-w-20 items-center justify-end space-x-2 lg:min-w-20 2xl:min-w-24">
            <p>{rate ? "$" + rate : "-"}</p>
            <Checkbox />
          </div>
          <Separator />
          <Breakdown data={amountBreakdown}>
            <div
              className={cn(
                "relative flex min-w-12 justify-end lg:min-w-10 2xl:min-w-14",
                amoutVariant === "profit"
                  ? "text-green-600"
                  : amoutVariant === "loss"
                    ? "text-red-600"
                    : "text-gray-500",
              )}
            >
              <div>
                ${amount} {amoutVariant === "profit" ? "+" : amoutVariant === "loss" ? "-" : "*"}
              </div>
              {animatePriceClick && (
                <span className="absolute left-6 top-0.5 inline-flex h-4 w-4 animate-ping rounded-full bg-sky-400 opacity-75 delay-700"></span>
              )}
            </div>
          </Breakdown>
          {!!part && (
            <>
              <Separator />
              <div className="pb-2 text-base text-gray-400">...</div>
            </>
          )}
        </>
      )}
      {!part && <Separator />}
      {(!part || part === "location" || part === "map") && (
        <>
          {!!part && (
            <>
              <div className="pb-2 pl-2 text-base text-gray-400">...</div>
              <Separator small />
            </>
          )}
          {part === "map" ? (
            <div className="flex min-w-[100px]">{origin}</div>
          ) : (
            <div className="flex w-full min-w-[110px] flex-row space-x-2 pr-2 lg:min-w-[180px] lg:space-x-2 2xl:min-w-[220px] 2xl:space-x-3 2xl:pr-4">
              <div className="flex min-w-8 justify-end pl-1 text-[#0046DF] lg:pl-3">{loadedMiles}</div>
              <div className="flex w-min pr-2 lg:w-full lg:pr-3">
                {part === "location" || part === "map" ? "..." : origin}
              </div>
              <div className="flex justify-end">({emptyMiles})</div>
            </div>
          )}
          <Arrow />
          <div className="flex w-full flex-row px-2 lg:px-4 2xl:px-6">
            <div
              className={cn(
                "flex w-full min-w-[82px] xl:min-w-[83px]",
                destinationVariant === "exception" ? "text-orange-500 line-through" : "",
              )}
            >
              {destination}
            </div>
          </div>
          {!!part && part !== "map" && (
            <>
              <Separator small />
              <div className="pb-2 pr-2 text-base text-gray-400">...</div>
            </>
          )}
        </>
      )}
      {!part && (
        <>
          <Separator />
          <div className="hidden xl:flex">{date}</div>
          <Separator className={"hidden xl:flex"} />
          <div className="flex w-full flex-row space-x-3 pr-2 xl:pr-4 2xl:space-x-4 2xl:pr-2">
            <div>{equipmentShort}</div>
            <div className="flex flex-col">
              <div>{equipmentFull}</div>
              <div className="-mt-0.5 flex flex-row items-center text-[11px] text-gray-500">
                <div className={cn(lengthVariant ? "text-orange-500 line-through" : "")}>{length} ft</div>
                <SeparatorSmall />
                <div className={cn(weightVariant ? "text-orange-500 line-through" : "")}>{weight} lbs</div>
                <SeparatorSmall className={"hidden xl:flex"} />
                <div className={"hidden xl:flex"}>{size}</div>
              </div>
            </div>
          </div>
          <Separator />
        </>
      )}
      {(!part || part === "broker" || part === "send") && (
        <>
          {!!part && (
            <>
              <div className="pb-2 pl-2 text-base text-gray-400">...</div>
              <Separator small />
            </>
          )}
          <div className="flex w-full flex-row">
            <div className="flex w-full min-w-[130px] flex-col justify-between lg:min-w-[140px] xl:min-w-[180px] 2xl:min-w-[145px]">
              <div
                className={cn(
                  "text-[#0046DF]",
                  brokerVariant === "onboarded"
                    ? "text-green-600 underline"
                    : brokerVariant === "blacklisted"
                      ? "text-orange-600 line-through"
                      : "",
                )}
              >
                {broker}
              </div>
              <div className={cn("-mt-1 text-[#0046DF] lg:mt-0", emailVariant ? "text-red-500 line-through" : "")}>
                {phone ?? email}
              </div>
            </div>
            <div className="flex min-w-10 flex-col items-end pl-4 text-[12px] md:hidden lg:pl-8 2xl:flex 2xl:pl-10">
              <div>{cs} CS</div>
              <div className="-mt-1 text-gray-500 lg:mt-0">{dtp} DTP</div>
            </div>
          </div>
          {!!part && part !== "send" && (
            <>
              <Separator small />
              <div className="pb-2 pr-2 text-base text-gray-400">...</div>
            </>
          )}
        </>
      )}
      {!part && <Separator />}
      {(!part || part === "map" || part === "send") && (
        <>
          {!!part && (
            <>
              <Separator small />
            </>
          )}
          <div className="relative flex w-min flex-row items-center space-x-4 lg:w-full">
            {(!part || part === "map") && (
              <Route
                className="cursor-pointer text-[#0046DF]"
                size={18}
                onClick={() => {
                  const url = `https://www.google.com/maps/dir/${origin?.replaceAll(" ", "+")}/${destination?.replaceAll(" ", "+")}`;
                  window.open(url, "_blank");
                }}
              />
            )}

            {(!part || part === "send") && (
              <>
                {email ? (
                  sendingState === "sent" ? (
                    <Check className={cn("text-green-600")} size={18} strokeWidth={2} />
                  ) : sendingState === "sending" ? (
                    <Loader className={cn("animate-spin text-[#0046DF] transition-all")} size={18} />
                  ) : (
                    <>
                      <SendHorizonal
                        className="z-10 cursor-pointer text-[#0046DF]"
                        size={18}
                        onClick={() => {
                          setSendingState("sending");
                          setTimeout(() => {
                            setSendingState("sent");
                            setTimeout(() => {
                              setSendingState("none");
                            }, 2000);
                          }, 2000);
                        }}
                      />
                      {animateSendClick && (
                        <span
                          className={cn(
                            "absolute top-0.5 inline-flex h-4 w-4 animate-ping rounded-full bg-sky-400 opacity-75 delay-700",
                            part === "send" ? "-left-4" : "left-5",
                          )}
                        ></span>
                      )}
                    </>
                  )
                ) : (
                  <div className="min-w-4" />
                )}
              </>
            )}
            {!!part && (
              <>
                <div />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export function TruckerPath({
  className,
  min,
  rate,
  amount,
  amoutVariant,
  amountBreakdown,
  loadedMiles,
  emptyMiles,
  origin,
  destination,
  destinationVariant,
  date,
  equipmentFull,
  weight,
  weightVariant,
  broker,
  brokerVariant,
  cs,
  dtp,
  index,
}) {
  return (
    <Divlr
      index={index * 0.3}
      className={cn(
        "flex min-h-[60px] w-full flex-row items-center whitespace-nowrap py-2.5 font-serious text-[12px] font-bold 2xl:text-[13px]",
        className,
      )}
    >
      <div className="flex min-w-32 items-center justify-center space-x-2">
        <div className="h-3 w-3 rounded-sm border-2 border-gray-400 xl:h-4 xl:w-4" />
        <p
          className={cn(
            "flex min-w-10 justify-center rounded-sm",
            min > 60 ? "bg-yellow-200 text-yellow-700" : "bg-green-200 text-green-700",
          )}
        >
          {min > 60 ? parseInt(min / 60) + "h" : min + "m"}
        </p>
      </div>
      <div className="flex w-full min-w-24">{origin}</div>
      <div className="flex px-8 xl:px-10">{emptyMiles} mi</div>
      <div className="hidden w-full 2xl:flex">{date}</div>
      <div
        className={cn("flex w-full min-w-28", destinationVariant === "exception" ? "text-orange-500 line-through" : "")}
      >
        {destination}
      </div>
      <div className="flex min-w-16">{loadedMiles} mi</div>
      <div className="flex w-full min-w-16 justify-end px-2">{equipmentFull}</div>
      <div className={cn("flex px-4", weightVariant ? "text-orange-500 line-through" : "")}>{weight} lbs</div>
      <div
        className={cn(
          "flex w-full min-w-24 px-6 text-[#0046DF] xl:px-12",
          brokerVariant === "onboarded"
            ? "text-green-600 underline"
            : brokerVariant === "blacklisted"
              ? "text-orange-600 line-through"
              : "",
        )}
      >
        {broker}
      </div>
      <div className="hidden min-w-12 flex-row space-x-2 2xl:flex">
        <div>{cs} CS</div>
        <div className="-mt-1 text-gray-500 lg:mt-0">{dtp} DTP</div>
      </div>
      <div className="flex w-full flex-col items-end px-10">
        <p className="-mt-0.5 text-[13px] text-green-700 2xl:text-[15px]">{rate ? "$" + rate : "-"}</p>
        <Breakdown data={amountBreakdown}>
          <div
            className={cn(
              "flex justify-end",
              amoutVariant === "profit" ? "text-green-600" : amoutVariant === "loss" ? "text-red-600" : "text-gray-500",
            )}
          >
            ${amount} {amoutVariant === "profit" ? "+" : amoutVariant === "loss" ? "-" : "*"}
          </div>
        </Breakdown>
      </div>
    </Divlr>
  );
}

// Preview

export function Description({ className, classes, title, line, table, index, small, disabled, children }) {
  return (
    <motion.div
      className={cn("flex flex-col space-y-1 pb-5", className, small ? "text-xl" : "text-[15px] 2xl:text-[16px]")}
      initial={{ opacity: 0.0, y: 40 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ delay: small ? 0 : 0.1 + 0.15 * index, duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }}
    >
      {line && <div className="mb-4 ml-4 h-10 w-[1px] border-l border-dashed border-gray-300 dark:border-gray-600" />}
      {title?.map?.((item, i) => (
        <div
          key={i}
          className={cn(
            "pt-[1px] text-black",
            classes?.title,
            small ? "font-serious text-4xl font-black leading-snug" : "font-bold leading-tight",
            disabled ? "opacity-30" : "",
          )}
        >
          {item}
        </div>
      ))}
      {table && <div className="pb-6 pt-8">{table}</div>}
      <div
        className={cn(
          "max-w-48 pt-1.5 font-light text-gray-600",
          classes?.description,
          small ? "max-w-max leading-loose" : "leading-[24px]",
          disabled ? "opacity-50" : "",
        )}
      >
        {children}
      </div>
    </motion.div>
  );
}

export function Middle({ className, line, index }) {
  return (
    <motion.div
      className={cn("min-w-16 pl-3 2xl:min-w-20", className)}
      initial={{ opacity: 0.0, y: 40 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.1 + 0.15 * index, duration: 0.6, ease: "easeInOut" }}
      viewport={{ once: true }}
    >
      {line && <div className="h-full w-[1px] border-l border-dashed border-gray-300 dark:border-gray-600" />}
    </motion.div>
  );
}

export function LoadsTable({ variant, part, animateType }) {
  let Component;
  if (variant === "truckerpath") {
    Component = TruckerPath;
  } else {
    Component = DATRow;
  }

  return (
    <div className="flex w-full flex-col overflow-x-scroll rounded-lg border border-gray-200 bg-gray-100 shadow-2xl">
      <Component
        part={part}
        index={1}
        variant={variant}
        className="w-full bg-gray-200"
        min={1}
        rate="1,140"
        amount="225"
        amoutVariant="profit"
        amountBreakdown={{
          rate: 1120,
          dho: 35,
          miles: 1150,
          gallon: 3.38,
          duration: 1.5,
          fees: 114,
          fuel: 323,
          truck: 440,
          trailer: 130,
          driver: 180,
          expenses: 1175,
          profit: 225,
        }}
        emptyMiles="20"
        loadedMiles="832"
        origin="Aurora, IL"
        destination="Brooklyn, NY"
        date="01/01"
        equipmentShort="V"
        equipmentFull="Van"
        length="48"
        weight="44,000"
        weightVariant="exception"
        size="Full"
        broker="Delivery LLC"
        phone="(777) 123-4567"
        cs="93"
        dtp="20"
        animatePriceClick={animateType == "profit" || animateType == "profit+send"}
      />
      <Component
        part={part}
        variant={variant}
        index={3}
        className="w-full bg-gray-100"
        min={4}
        rate="1,120"
        amount="35"
        amoutVariant="loss"
        amountBreakdown={{
          rate: 1140,
          dho: 35,
          miles: 1150,
          gallon: 3.38,
          duration: 2,
          fees: 112,
          fuel: 403,
          truck: 370,
          trailer: 130,
          driver: 240,
          expenses: 975,
          losses: -35,
        }}
        emptyMiles="35"
        loadedMiles="1150"
        origin="Chicago, IL"
        destination="Austin, Tx"
        date="01/01"
        equipmentShort="V"
        equipmentFull="Van"
        length="53"
        lengthVariant="exception"
        weight="24,000"
        size="Full"
        broker="Onboarded Inc"
        brokerVariant="onboarded"
        email="loads@onboarded.com"
        cs="98"
        dtp="22"
      />
      <Component
        part={part}
        index={7}
        variant={variant}
        className="w-full bg-gray-200"
        min={120}
        rate={null}
        amount="630"
        amoutVariant="cost"
        amountBreakdown={{
          dho: 92,
          miles: 409,
          gallon: 3.38,
          duration: 1.5,
          fees: 90,
          fuel: 230,
          truck: 200,
          trailer: 80,
          driver: 130,
          expenses: 630,
        }}
        emptyMiles="92"
        loadedMiles="409"
        origin="Fort Wayne, IL"
        destination="Nashville, TN"
        destinationVariant="exception"
        date="01/01"
        equipmentShort="V"
        equipmentFull="Van"
        length="48"
        weight="20,000"
        size="Full"
        broker="Scammer LLC"
        brokerVariant="blacklisted"
        email="email@scammer.com"
        emailVariant="scammer"
        cs="89"
        dtp="20"
        animateSendClick={animateType == "send" || animateType == "profit+send"}
        animatePriceClick={animateType == "expenses"}
      />
    </div>
  );
}

const DescriptionProfitability = ({ small }) => {
  return (
    <Description
      title={["Similar Rate", "Different Profit"]}
      line={!small}
      index={1}
      small={small}
      table={small && <LoadsTable variant="dat" part="rate" animateType="profit" />}
    >
      Book <span className="font-bold text-green-600">profitable $+</span> loads to avoid{" "}
      <span className="font-bold text-red-600">losses $-</span>, understand costs to stay profitable (click on profit to
      see the breakdown)
    </Description>
  );
};

const DescriptionNoProfit = ({ small }) => {
  return (
    <Description
      title={["No Rate", "No Problem"]}
      index={2}
      small={small}
      table={small && <LoadsTable variant="dat" part="rate" animateType="expenses" />}
    >
      We will show you <span className="font-bold text-gray-600">expenses $*</span> that would allow you to have better
      starting point for negotiation
    </Description>
  );
};

const DescriptionDriverPreferences = ({ small }) => {
  return (
    <Description
      title={["Apply Driver", "Preferences"]}
      line={!small}
      index={3}
      small={small}
      table={small && <LoadsTable variant="dat" part="location" />}
    >
      Highlight <span className="font-bold text-orange-600">states</span> drivers don't go to or size of the load they
      prefer to take
    </Description>
  );
};

const DescriptionFleetPreferences = ({ small }) => {
  return (
    <Description
      title={["Apply Fleet", "Preferences"]}
      index={4}
      small={small}
      table={small && <LoadsTable variant="dat" part="broker" />}
    >
      Highlight brokers that are <span className="font-bold text-green-600">onboarded</span> or{" "}
      <span className="font-bold text-orange-600">blacklisted</span> brokers for a specific fleet you have
    </Description>
  );
};

const DescriptionFraud = ({ small }) => {
  return (
    <Description
      title={["Avoid", "Fraud and Scam"]}
      line={!small}
      index={5}
      small={small}
      table={small && <LoadsTable variant="dat" part="broker" />}
    >
      Save contacts you would like your team to avoid contacting to{" "}
      <span className="font-bold text-red-600">highlight</span> them
    </Description>
  );
};

const DescriptionGoogleMaps = ({ small, disabled }) => {
  return (
    <Description
      title={[
        <div className="flex flex-row">
          Open{" "}
          <Route className="ml-2.5 mt-2 h-[40px] w-[40px] text-blue-600 lg:-mt-0.5 lg:ml-1.5 lg:h-[20px] lg:w-[20px]" />
        </div>,
        "Google Maps",
      ]}
      disabled={disabled}
      index={6}
      small={small}
      table={small && <LoadsTable variant="dat" part="map" />}
    >
      Open new tab with google maps by clicking map icon
    </Description>
  );
};

const DescriptionEmails = ({ small, disabled }) => {
  return (
    <Description
      title={[
        <div className="flex flex-row">
          Send{" "}
          <SendHorizonal className="ml-2.5 mt-2 h-[40px] w-[40px] text-blue-600 lg:-mt-0.5 lg:ml-1.5 lg:h-[20px] lg:w-[20px]" />
        </div>,
        "Emails to Brokers",
      ]}
      disabled={disabled}
      index={7}
      line={!small}
      small={small}
      table={small && <LoadsTable variant="dat" part="send" animateType="send" />}
    >
      Send emails to brokers in a single click, we will pre-fill your template with load details
    </Description>
  );
};

export function LandingLoadsExamples() {
  const [activeTab, setActiveTab] = useState("dat");

  return (
    <div className="flex bg-gray-50 px-2 py-10 dark:bg-gray-300 dark:text-black lg:bg-gray-100 lg:px-20 lg:py-16 xl:px-24 2xl:px-32">
      <div className="flex w-full flex-col">
        <div className="hidden flex-col overflow-y-clip lg:flex">
          <Divlr className="flex w-min flex-row p-1.5" index={3}>
            <Tab label={"DAT One"} tab="dat" activeTab={activeTab} setActiveTab={setActiveTab} />
            <Tab label={"Trucker Path"} tab="truckerpath" activeTab={activeTab} setActiveTab={setActiveTab} />
          </Divlr>
          <Divlr className="mb-5 ml-2 text-[12px] text-gray-400 dark:text-gray-600" index={5}>
            *More being added, let us know which one you want us to add next
          </Divlr>
          <LoadsTable variant={activeTab} animateType="profit" />
          <div className="flex flex-row justify-between space-x-3 pl-1 pt-10 xl:pl-4">
            <DescriptionProfitability />
            <Middle line index={2} />
            <DescriptionDriverPreferences />
            <Middle line index={3} />
            <DescriptionFraud />
            <Middle line index={6} />
            <DescriptionEmails disabled={activeTab === "truckerpath"} />
          </div>
          <div className="flex flex-row justify-between space-x-3 pl-20 pt-6 2xl:pl-24">
            <Middle index={1} />
            <DescriptionNoProfit />
            <Middle index={3} />
            <DescriptionFleetPreferences />
            <Middle index={5} />
            <DescriptionGoogleMaps disabled={activeTab === "truckerpath"} />
            <Middle index={7} />
          </div>
        </div>
        <div className="flex flex-col space-y-10 overflow-y-clip px-5 lg:hidden">
          <DescriptionProfitability small />
          <DescriptionNoProfit small />
          <DescriptionEmails small />
          <DescriptionGoogleMaps small />
          <DescriptionDriverPreferences small />
        </div>
      </div>
    </div>
  );
}
