import moment from "moment";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { lazy } from "react";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function headerWarning(user, statusOnly) {
  const now = moment();
  const subscription = user?.session?.subscription;

  if (subscription?.subscriptionStatus === "trial") {
    if (statusOnly) return { action: "subscribe" };

    if (subscription.trialEndsAt?.length > 0) {
      const trialEndsAt = moment(subscription.trialEndsAt);
      if (trialEndsAt.isAfter(now)) {
        return {
          label: `Trial ends ${trialEndsAt.format("MMMM Do")}`,
          level: "warning",
          action: "subscribe",
        };
      } else {
        return {
          label: `Trial ended on ${trialEndsAt.format("MMMM Do")}`,
          level: "error",
          action: "subscribe",
        };
      }
    }
  } else if (subscription?.subscriptionStatus === "subscribed" && subscription.subscriptionEndsAt?.length > 0) {
    if (statusOnly) return { action: "subscribe" };

    return {
      label: `Subscription will be canceled on ${moment(subscription.subscriptionEndsAt).format("MM/DD")}`,
      level: "warning",
      action: "subscribe",
    };
  } else if (subscription?.subscriptionStatus === "unsubscribed") {
    if (statusOnly) return { action: "subscribe" };

    return {
      label: `Subscription was canceled and account is disabled`,
      level: "error",
      action: "subscribe",
    };
  }
  return null;
}

export function formatPhoneNumber(phone) {
  var cleaned = ("" + phone).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phone;
}

export function formatBytes(size) {
  return !size
    ? "0 B"
    : size < 1024
      ? size + " B"
      : size < 1024 * 1024
        ? parseInt(size / 1024) + " KB"
        : parseInt((size / 1024 / 1024) * 10) / 10 + " MB";
}

export function formatDate(input, options) {
  let date = input;
  let time = "-";
  let separator = " ";

  if (input?.length) {
    const value = moment(input);
    if (value.isValid) {
      const sameYear = value.isSame(moment(), "year");
      date = value.format(sameYear ? (options?.military ? "MM/DD" : "MMM DD") : "MM/DD/YYYY");
      separator = sameYear ? (options?.military ? " " : ", ") : " ";
      time = value.format("h:mm a");
    }
  }

  return { formatted: date + separator + (time != "00:00" ? time : ""), date, time };
}

export function errorMessage(err) {
  if (!err) return null;

  let message;
  if (err?.response?.data?.reason?.length > 0) {
    message = err?.response?.data?.reason;
  } else if (err?.response?.data?.message?.length > 0) {
    message = err?.response?.data?.message;
  } else if (err?.message?.length > 0) {
    message = err?.message;
  } else {
    message = "Oops, something went wrong, please try again";
  }

  return message.charAt(0).toUpperCase() + message.slice(1);
}

export const greenLabel = `h-min whitespace-nowrap rounded-sm border px-[5px] py-[2px] text-[12px] font-medium capitalize leading-none border-green-500 dark:border-green-600 bg-green-50 dark:bg-green-700 text-green-800 dark:text-green-100`;
export const redLabel = `h-min whitespace-nowrap rounded-sm border px-[5px] py-[2px] text-[12px] font-medium capitalize leading-none border-red-500 dark:border-red-600 bg-red-50 dark:bg-red-800 text-red-700 dark:text-red-100`;
export const yellowLabel = `h-min whitespace-nowrap rounded-sm border px-[5px] py-[2px] text-[12px] font-medium capitalize leading-none border-yellow-500 dark:border-yellow-600 bg-yellow-50 dark:bg-yellow-700 text-yellow-800 dark:text-yellow-100`;
export const blueLabel = `h-min whitespace-nowrap rounded-sm border px-[5px] py-[2px] text-[12px] font-medium capitalize leading-none border-blue-500 dark:border-blue-600 bg-blue-50 dark:bg-blue-700 text-blue-800 dark:text-blue-100`;

const defaultLabel =
  "text-slate-500 dark:text-slate-400 border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-700 font-medium border-[1px]";
const genericUser =
  "text-gray-900 dark:text-gray-300 border-gray-300 dark:border-gray-500 bg-gray-100 dark:bg-gray-600 border-[1px]";
const rootLabel =
  "text-red-800 dark:text-red-200 border-red-300 dark:border-red-600 bg-red-100 dark:bg-red-800 border-[1px]";
const positiveLabel =
  "text-green-800 dark:text-green-100 border-green-300 dark:border-green-600 bg-green-50 dark:bg-green-700 border-[1px]";
const warningLabel =
  "text-yellow-800 dark:text-yellow-100 border-yellow-300 dark:border-yellow-600 bg-yellow-50 dark:bg-yellow-700 border-[1px]";
const archivedLabel =
  "text-red-800 dark:text-red-200 border-red-300 dark:border-red-600 bg-red-100 dark:bg-red-800 border-[1px] ";
const infoLabel =
  "text-blue-800 dark:text-blue-200 border-blue-300 dark:border-blue-600 bg-blue-100 dark:bg-blue-800 border-[1px] ";

export const labelVariant = {
  default: defaultLabel,
  system: rootLabel,
  root: rootLabel,
  driver: genericUser,
  investor: genericUser,
  manager: genericUser,
  dispatcher: genericUser,
  admin: genericUser,
  owner: warningLabel,
  archived: archivedLabel,
  inactive: warningLabel,
  enabled: positiveLabel,
  full: positiveLabel,
  limited: warningLabel,
  customer: genericUser,
  debug: rootLabel,
  primary: positiveLabel,
  onboarded: positiveLabel,
  blacklisted: archivedLabel,
  auto: positiveLabel,
  public: positiveLabel,
  preview: warningLabel,
  pending: warningLabel,
  "bonus added": positiveLabel,
  credited: positiveLabel,
  referral: infoLabel,
  finished: positiveLabel,
  executing: warningLabel,
  failed: archivedLabel,
  interrupted: archivedLabel,
};

export function createLables(data) {
  const labels = [];
  if (data?.archived) {
    labels.push("archived");
  }
  if (data?.deactivated) {
    labels.push("inactive");
  }

  const parts = data?.labels?.split(", ");
  parts?.forEach((item) => {
    const cleaned = item?.trim()?.toLowerCase();
    if (cleaned?.length > 0) {
      labels.push(cleaned);
    }
  });

  return labels.map((label) => ({
    value: label,
    classes: labelVariant[label] ?? labelVariant.default,
  }));
}

export const lazyPreload = (importStatement) => {
  const Component = lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};
