import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export function useAppLocation() {
  const location = useLocation();

  const user = useSelector((state) => state.user);

  const { isAuth, isApp, isDocs, isTransparent } = useMemo(
    () => ({
      isTransparent:
        location?.pathname?.startsWith("/pricing") ||
        location?.pathname?.startsWith("/version") ||
        location?.pathname?.startsWith("/contact") ||
        location?.pathname?.startsWith("/docs"),
      isApp: location?.pathname?.startsWith("/app") && !!user.token && !user.error,
      isAuth:
        (location?.pathname?.startsWith("/app") && (!user.token || user.error)) ||
        location?.pathname?.startsWith("/signin") ||
        location?.pathname?.startsWith("/signup") ||
        location?.pathname?.startsWith("/postmessage"),
      isDocs: location?.pathname?.startsWith("/docs"),
    }),
    [location, user],
  );

  return { isAuth, isApp, isDocs, isTransparent };
}
