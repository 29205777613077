import { useAppLocation } from "@/hooks/navigation";

import { Divlr } from "@/components/animations/animated-divs";
import { Bird } from "lucide-react";

import { AuroraBackground } from "@/components/animations/aurora-background";

function PageNotFound() {
  const { isApp } = useAppLocation();

  if (isApp) {
    return (
      <div className="relative">
        <div className="flex h-[90vh] items-center justify-center px-4 md:space-x-20">
          <div index={3} className="flex flex-row items-center space-x-8 px-8 py-6 pb-20 capitalize">
            <Bird size={30} />
            <div className="flex flex-col space-y-2">
              <p className="text-lg">{"Hm..."}</p>
              <p className="text-lg capitalize">Page Not Found</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <AuroraBackground className={"h-screen"}>
      <div className="h-screen pt-20">
        <div className="relative flex items-center justify-center px-4 md:space-x-20">
          <div className="hidden h-[70vh] w-[1px] bg-slate-300 opacity-30 md:flex" />
          <Divlr
            index={3}
            className="flex flex-col rounded-lg border-[1px] border-slate-200 bg-white shadow-2xl md:w-[30rem]"
          >
            <div index={3} className="flex flex-row items-center space-x-8 px-8 py-6 capitalize">
              <Bird size={30} />
              <div className="flex flex-col space-y-2">
                <p className="text-lg">{"Hm..."}</p>
                <p className="text-lg capitalize">Page Not Found</p>
              </div>
            </div>
          </Divlr>
          <div className="hidden h-[70vh] w-[1px] bg-slate-300 opacity-30 md:flex" />
        </div>
      </div>
    </AuroraBackground>
  );
}

export default PageNotFound;
