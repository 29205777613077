import * as React from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { useRect } from "@radix-ui/react-use-rect";

import { cn } from "@/lib/utils";

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverAnchor = PopoverPrimitive.Anchor;

const PopoverContent = React.forwardRef(
  ({ className, overflowOff, align = "center", sideOffset = 4, ...props }, ref) => {
    const [content, setContent] = React.useState();
    const rect = useRect(ref ?? content);

    return (
      <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
          ref={overflowOff ? (ref ?? setContent) : ref}
          align={align}
          sideOffset={sideOffset}
          className={cn(
            "z-50 w-72 rounded-md border border-slate-200 bg-white p-4 text-slate-950 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:border-[1px] dark:border-gray-600 dark:bg-[#202126] dark:text-slate-200",
            className,
          )}
          {...props}
          style={{
            ...(props.style ?? {}),
            maxHeight: overflowOff ? `calc(100vh - ${rect?.top || 0}px - ${sideOffset * 5}px)` : props.style?.maxHeight,
            overflow: "auto",
          }}
        />
      </PopoverPrimitive.Portal>
    );
  },
);
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { Popover, PopoverTrigger, PopoverContent, PopoverAnchor };
