import React from "react";
import { Link, useLocation } from "react-router-dom";

import { ChevronRight } from "lucide-react";
import { cn } from "@/lib/utils";

const Section = ({ title, children, className }) => {
  return (
    <div className={cn("flex flex-col space-y-4 text-[14px] tracking-wide lg:text-[12px] 2xl:text-[13px]", className)}>
      <div className="font-bold uppercase text-gray-500 dark:text-gray-200">{title}</div>
      <div className="space-y-3.5 dark:text-gray-400">{children}</div>
    </div>
  );
};

const Item = ({ label, to, location }) => {
  return (
    <div className="flex flex-col text-[16px] tracking-normal lg:text-[14px] 2xl:text-[15px]">
      <Link className="flex" to={to}>
        <div
          className={cn(
            "flex flex-row items-center space-x-1.5",
            location?.pathname?.startsWith(to) ? "font-bold" : "",
          )}
        >
          <ChevronRight className="-ml-1 text-gray-500" size={18} />
          <div>{label}</div>
        </div>
      </Link>
    </div>
  );
};

function DocSidebar({ className }) {
  const location = useLocation();

  return (
    <div className={cn("flex flex-col space-y-8", className)}>
      <Section title="Chrome Extension">
        <Item to="/docs/extension/quickstart" label={"Quick Start"} location={location} />
        <Item to="/docs/extension/email-brokers" label={"Send Emails to Brokers"} location={location} />
        <Item to="/docs/extension/calculate-profit" label={"Calculate Load Profit"} location={location} />
      </Section>
      <div className="h-[1px] w-full bg-gray-200 dark:bg-gray-600" />
      <Section title="Policies">
        <Item to="/docs/policies/privacy" label={"Privacy Policy"} location={location} />
        <Item to="/docs/policies/terms" label={"Terms of Use"} location={location} />
      </Section>
    </div>
  );
}

export default DocSidebar;
