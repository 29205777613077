import { lazy, Suspense, useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "universal-cookie";

// Functions
import { cn, headerWarning } from "@/lib/utils";
import { useAppLocation } from "@/hooks/navigation";
import { useScreenPosition } from "@/hooks/screen";
import { triggerSidebar } from "@/stores/navSlice";
import { clearUser } from "@/stores/userSlice";
import { clearConfig } from "@/stores/configSlice";
import { setTheme } from "@/stores/themeSlice";

// Components
import { Divlr } from "@/components/animations/animated-divs";
import { Button } from "@/components/ui/button";
import { NavLogoLanding, NavLogoApp } from "@/components/theme";
import { CircleUser, MenuIcon } from "lucide-react";
import { HoveredLink, Menu, MenuItem, ProductItem } from "@/components/ui/navbar-menu";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import ThemeSwitcher from "@/components/theme/ThemeSwitcher";
import WarningBar from "./nav/WarningBar";
import { clearStorageSafe } from "@/lib/storage";
const LoginAsDialog = lazy(() => import("@/pages/nav/LoginAsDialog"));

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const cookies = new Cookies(null, { path: "/" });

  const user = useSelector((state) => state.user);
  const [active, setActive] = useState();
  const [logoutAs, setLogoutAs] = useState();

  const loginAsRef = useRef();

  const { isAuth, isApp, isTransparent } = useAppLocation();
  const { isTop, isTopInit } = useScreenPosition({ pathname: location.pathname, isApp });

  const name = useMemo(() => {
    const name = (user?.session?.profile?.firstName + " " + user?.session?.profile?.lastName)?.trim();
    if (name?.length > 0) return name;
    return user?.session?.user?.email;
  }, [user]);

  useEffect(() => {
    if (isApp) {
      const token = localStorage.getItem("signout_token");
      const url = localStorage.getItem("signout_url");
      if (token?.length > 0) {
        setLogoutAs({ token, url });
      } else {
        setLogoutAs(null);
      }
    } else if (isAuth) {
      setLogoutAs(null);
    }
  }, [isApp, isAuth]);

  const warningBar = useMemo(() => (isApp ? headerWarning(user) : null), [isApp, user]);

  if (isAuth) return null;

  if (isApp)
    return (
      <div
        className={cn(
          "fixed inset-x-0 z-50 rounded-none border-b-[1px] border-gray-200 bg-white bg-opacity-95 dark:border-black dark:bg-[#202126]",
        )}
      >
        {warningBar && <WarningBar {...warningBar} />}
        <div className="flex h-14 flex-row">
          <Menu setActive={setActive} className={cn("flex h-full w-full items-center border-gray-100 py-0 pl-4 pr-8")}>
            <MenuIcon
              size={22}
              className="ml-2 mr-3 flex cursor-pointer dark:text-gray-200 md:ml-0 md:mr-1 xl:hidden"
              onClick={() => dispatch(triggerSidebar())}
            />
            <NavLogoApp />
            <div className="flex-grow" />
            <div className={"flex flex-row items-center"} index={0}>
              <div className="hidden flex-row items-center md:flex">
                <Link
                  to={"/contact?target=support"}
                  className="flex flex-row text-nowrap text-sm font-medium text-slate-700 dark:text-gray-400"
                >
                  Help
                </Link>
                <div className="mx-5 h-8 w-[1px] bg-gray-200 dark:bg-gray-700" />
                <ThemeSwitcher />
                <div className="mx-5 h-8 w-[1px] bg-gray-200 dark:bg-gray-700" />
              </div>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <CircleUser size={20} className="cursor-pointer text-slate-700 dark:text-gray-400" />
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-56">
                  {name?.length > 0 && (
                    <DropdownMenuLabel className="mb-1 truncate whitespace-nowrap rounded-sm bg-slate-400 text-sm font-bold text-white dark:bg-gray-600 dark:text-gray-200">
                      {name}
                    </DropdownMenuLabel>
                  )}
                  <DropdownMenuItem
                    className="hidden md:flex"
                    onClick={() => {
                      for (var key in localStorage) {
                        if (key.startsWith("_table_")) {
                          localStorage.removeItem(key);
                        }
                      }
                      window.location.reload();
                    }}
                  >
                    <div>
                      <p>Reset Visuals</p>
                      <p className="text-xs text-gray-400">Reset table customized colums sizes and visible columns</p>
                    </div>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    className="flex md:hidden"
                    onClick={() => {
                      navigate("/contact?target=support");
                    }}
                  >
                    Help
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => {
                      navigate("/docs");
                    }}
                  >
                    Documentation
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => {
                      navigate("/app/settings");
                    }}
                  >
                    Settings
                  </DropdownMenuItem>

                  {user?.session?.account?.variant === "system" && !logoutAs && (
                    <>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem
                        onClick={() => {
                          setTimeout(() => {
                            loginAsRef.current?.open?.();
                          }, 250);
                        }}
                      >
                        Impersonate
                      </DropdownMenuItem>
                    </>
                  )}
                  {logoutAs && (
                    <>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem
                        className="bg-red-500 text-white focus:bg-red-600 focus:text-white dark:bg-red-700 dark:focus:bg-red-800"
                        onClick={() => {
                          clearStorageSafe();
                          var expires = new Date();
                          expires.setDate(expires.getDate() + 30);
                          cookies.set("104jwc", logoutAs.token, { expires });
                          window.location.replace(logoutAs.url);
                        }}
                      >
                        Stop Impersonation
                      </DropdownMenuItem>
                    </>
                  )}
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    onClick={() => {
                      clearStorageSafe();
                      cookies.remove("104jwc", { path: "/" });
                      dispatch(clearUser());
                      dispatch(clearConfig());
                      dispatch(setTheme("light"));
                      navigate("/");
                    }}
                  >
                    Log Out
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </Menu>
          <Suspense>
            <LoginAsDialog ref={loginAsRef} />
          </Suspense>
        </div>
      </div>
    );

  return (
    <div
      className={cn("inset-x-0 z-50 mx-auto w-full", !isTopInit ? "fixed" : isTop ? "absolute" : "fixed", {
        "border-b bg-white bg-opacity-95 dark:border-black dark:bg-[#202126]": !isTop || isTransparent,
        "duration-300": !isTransparent,
        "transition-colors": isTopInit,
      })}
    >
      <Menu setActive={setActive}>
        <div
          className={cn(
            "flex w-full items-center space-x-3 px-[0.5rem] lg:space-x-5 lg:px-[3rem] xl:px-[5rem] 2xl:px-[9rem]",
          )}
        >
          <Divlr index={3} onMouseOver={() => setActive(null)}>
            <NavLogoLanding to={!!user?.token ? "/app/home" : "/"} />
          </Divlr>
          <Divlr index={4} className="h-1 w-1 rounded bg-black dark:bg-gray-400" onMouseOver={() => setActive(null)} />
          <MenuItem delay={0.6} setActive={setActive} active={active} item="Products" className="hidden lg:flex">
            <div className="flex flex-col p-4 text-sm">
              <ProductItem
                to={!user?.token ? "/" : "/plugin"}
                title="Loadboard Plugin"
                description="Supercharge your favorite load boards and take advantage of our pluging to make right decisions fast."
                setActive={setActive}
              />
            </div>
          </MenuItem>
          <HoveredLink setActive={setActive} delay={0.8} to="/pricing">
            Pricing
          </HoveredLink>
          <div className="flex-grow" />

          {!user?.token ? (
            <>
              <HoveredLink delay={0.6} setActive={setActive} to={"/contact?target=sales"} className="hidden lg:flex">
                Contact Sales
              </HoveredLink>
              <Divlr index={4} onMouseOver={() => setActive(null)}>
                <Button variant="outline" className="p-0 opacity-80">
                  <Link className="px-4 py-2" to={"signin"}>
                    Sign in
                  </Link>
                </Button>
              </Divlr>
            </>
          ) : (
            <>
              <HoveredLink className="hidden md:flex" setActive={setActive} delay={0.4} to="/docs/policies/privacy">
                Policy
              </HoveredLink>
              <HoveredLink setActive={setActive} delay={0.5} to="/docs">
                Docs
              </HoveredLink>
              <HoveredLink
                delay={0.6}
                setActive={setActive}
                to={"/contact?target=support"}
                className="hidden pr-2 lg:flex"
              >
                Help
              </HoveredLink>
              <Divlr index={4} onMouseOver={() => setActive(null)}>
                <Button className="p-0 dark:bg-gray-700 dark:text-white hover:dark:bg-gray-600">
                  <Link className="px-4 py-2" to={"/app"}>
                    Dashboard
                  </Link>
                </Button>
              </Divlr>
            </>
          )}
        </div>
      </Menu>
    </div>
  );
}
