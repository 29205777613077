import { Img } from "react-image";

import { Braces, MonitorCog, MoveRight, SlidersHorizontal } from "lucide-react";
import LaptopDatImg from "@/assets/laptop-dat.avif";
import { Link } from "react-router-dom";

const HowTo = ({ Icon, title, description, to, toText }) => {
  return (
    <div className="flex max-w-max flex-col space-y-1 lg:max-w-96">
      <div className="flex flex-row items-center space-x-4 2xl:space-x-6">
        <div className="flex min-h-10 min-w-10 items-center justify-center rounded-full bg-indigo-600 text-lg text-white 2xl:min-h-12 2xl:min-w-12">
          <Icon className="h-5 w-5" />
        </div>
        <div className="whitespace-nowrap font-serious text-xl font-black dark:text-gray-200 2xl:text-2xl">{title}</div>
      </div>
      <div className="pl-[56px] text-lg font-light leading-8 text-gray-600 dark:text-gray-400 lg:text-xl lg:leading-10 2xl:pl-[72px]">
        {description}
      </div>
      {to && (
        <Link
          className="flex flex-row items-center pl-[56px] pt-1 text-base font-bold leading-8 text-indigo-600 dark:text-indigo-400 xl:text-lg xl:leading-10 2xl:pl-[72px]"
          to={to}
        >
          <p>{toText ?? "Learn More"}</p> <MoveRight className="ml-2" size={22} strokeWidth={2} />
        </Link>
      )}
    </div>
  );
};

export function LandingHowToSection() {
  return (
    <div className="relative flex w-full flex-col space-y-20 pb-16 lg:space-y-28 lg:pb-28">
      <div className="flex w-full items-center px-6 pt-16 md:min-h-[280px] lg:hidden lg:pt-0">
        <Img className={"h-full w-full object-fill transition duration-500"} src={LaptopDatImg} alt={"image"} />
      </div>
      <div className="grid grid-cols-1 gap-14 pl-7 pr-7 lg:grid-cols-3 lg:gap-24 lg:pl-20 lg:pr-16 2xl:gap-6 2xl:pl-24 2xl:pr-32">
        <div className="flex flex-row justify-center">
          <HowTo
            Icon={Braces}
            title="Email Brokers"
            description="Send emails to brokers in 1 click. Connect your email and create custom email templates to pre-fill it with load details directly from the load board."
            toText="Watch Video"
            to="/docs/extension/email-brokers"
          />
        </div>
        <div className="flex flex-row justify-center">
          <HowTo
            Icon={SlidersHorizontal}
            title="Preferences and Profit"
            description="Create new account and add your fleets with their preferences. Add trucks and drivers with their costs and payments so we can calculate load profit."
            toText="Watch Video"
            to="/docs/extension/calculate-profit"
          />
        </div>
        <div className="flex flex-row justify-center">
          <HowTo
            Icon={MonitorCog}
            title="How it Works?"
            description="Use Chrome browser and install our Chrome Browser Extension that would allow you to add additional functionality to eligible load boards."
            to="/docs/extension/quickstart"
            toText="Quick Start"
          />
        </div>
      </div>
    </div>
  );
}
