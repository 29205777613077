import { useState, useEffect } from "react";

export function useScreenPosition({ pathname, isApp } = {}) {
  const [top, setTop] = useState(true);
  const [initialized, setInitialized] = useState(null);

  useEffect(() => {
    setInitialized(pathname);

    if (isApp) {
      return;
    }

    setTop(() => window.scrollY <= 0);
    const listener = () => {
      setTop(() => window.scrollY <= 0);
    };

    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, [pathname, isApp]);

  return { isTop: top, isTopInit: initialized == pathname };
}

export function useScreenSize() {
  const [xl, setXl] = useState(true);

  useEffect(() => {
    setXl(window.innerWidth >= 1280);
    const listener = () => {
      setXl(() => window.innerWidth >= 1280);
    };

    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  return { isXL: xl };
}
