import { motion } from "framer-motion";
import { useSelector } from "react-redux";

import LaptopFleetImg from "@/assets/laptop-dark-fleet.avif";
import LaptopTruckImg from "@/assets/laptop-dark-truck.avif";
import LaptopDatImg from "@/assets/laptop-dat.avif";
import LaptopTemplateImg from "@/assets/laptop-light-template.avif";
import TopSectionBackground from "@/assets/top-section-background.avif";
import { AuroraBackground } from "@/components/animations/aurora-background";
import { Button } from "@/components/ui/button";
import { lazy, Suspense, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const SpeedText = lazy(() => import("./SpeedText"));

import { cn } from "@/lib/utils";
import { Img } from "react-image";

const images = [LaptopDatImg, LaptopTruckImg, LaptopTemplateImg, LaptopFleetImg];

export function LandingTopSection() {
  const user = useSelector((state) => state.user);
  const system = useSelector((state) => state.system);
  const [isLoading, setLoading] = useState(true);
  const [isChanging, setChanging] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [showImage, setShowImage] = useState(0);

  const nextImage = () => {
    setChanging(true);
    setTimeout(() => setShowImage(showImage + 1 < images?.length ? showImage + 1 : 0), 500);
  };

  useEffect(() => {
    if (isLoading) return;
    const timeout = setTimeout(() => nextImage(), 15000);
    return () => clearTimeout(timeout);
  }, [showImage, isLoading]);

  return (
    <AuroraBackground
      staticBackground={system.os !== "macos" ? TopSectionBackground : null}
      className="h-[91vh] min-h-[700px] lg:h-[90vh]"
    >
      <motion.div
        className={cn(
          "absolute -mb-6 hidden flex-col pt-10 lg:flex",
          system.os !== "macos" ? "right-14 w-[45vw] 2xl:w-[44vw]" : "right-10 w-[46vw] 2xl:w-[45vw]",
        )}
        initial={{ opacity: 0.0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.7, duration: 0.8, ease: "easeInOut" }}
        viewport={{ once: true }}
      >
        <Img
          className={cn(
            "h-full w-full object-fill transition duration-500",
            isLoading || isChanging ? "blur-sm" : "blur-0",
            isChanging ? "opacity-0" : "opacity-1",
          )}
          loading="lazy"
          onLoad={() => {
            if (initialized) {
              setTimeout(
                () => {
                  setLoading(false);
                  setChanging(false);
                },
                isChanging ? 500 : 1000,
              );
            } else {
              setInitialized(true);
              setLoading(false);
              setChanging(false);
            }
          }}
          src={images[showImage]}
          alt={"image"}
        />
        <div
          className={cn(
            "flex flex-row justify-center space-x-3 pt-6 transition duration-500",
            isChanging ? "opacity-0" : "opacity-1",
          )}
        >
          {images.map((_, index) => (
            <div
              key={index}
              className={cn("h-2 w-2 rounded-full", index === showImage ? "bg-indigo-400" : "bg-black opacity-10")}
            />
          ))}
        </div>
      </motion.div>
      <div className="relative flex h-[93vh] w-full flex-row items-center space-x-6 lg:h-[90vh]">
        <motion.div
          className="flex h-min w-full flex-col items-start justify-center space-y-1.5 pl-10 pr-10 md:space-y-0 md:pl-14 lg:space-y-3 lg:pl-20 xl:pl-28 2xl:pl-44"
          initial={{ opacity: 0.0, y: 40 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.8, ease: "easeInOut" }}
          viewport={{ once: true }}
        >
          <div className="mb-1 pl-1 pt-0 text-sm font-bold uppercase text-indigo-700 dark:text-indigo-400 lg:pt-12 xl:pt-2">
            #1 Tool For Dispatchers
          </div>
          <div
            className={cn(
              "bg-grey max-w-[500px] font-serious text-5xl font-black opacity-90 dark:text-white md:font-extrabold lg:text-5xl xl:max-w-[700px]",
              system.os !== "macos" ? "xl:text-5xl 2xl:text-6xl" : "xl:text-6xl 2xl:text-7xl",
            )}
          >
            <div className="hidden flex-col lg:flex">
              {system.os !== "macos" ? (
                <div>
                  <div className="py-2 lg:whitespace-nowrap">Supercharge your </div>
                </div>
              ) : (
                <Suspense
                  fallback={
                    <div>
                      <div className="py-2 lg:whitespace-nowrap">Supercharge your </div>
                    </div>
                  }
                >
                  <div className="lg:whitespace-nowrap">
                    <SpeedText className={"-mb-10 -ml-2 -mr-2"}>Supercharge</SpeedText> your
                  </div>
                </Suspense>
              )}
              <div className="lg:whitespace-nowrap">favorite load boards</div>
            </div>
            <div className="flex lg:hidden">
              <p className="leading-[3.5rem] lg:leading-[4rem]">
                Supercharge
                <br />
                your
                <br />
                favorite
                <br />
                load boards
              </p>
            </div>
          </div>
          <ul className="max-w-[35rem] list-disc space-y-1.5 pb-6 pl-6 pt-4 text-lg font-light opacity-70 dark:text-white lg:space-y-2 lg:pb-10 lg:pt-8 lg:text-xl">
            <li>Email brokers instantly</li>
            <li>Calculate load profit and RPM</li>
            <li>Apply driver and fleet preferences</li>
            <li>Access maps in a single click</li>
            <li>Prevent fraud</li>
          </ul>
          <div className="flex flex-row space-x-4 pl-2">
            {!user?.token && (
              <Button className="bg-indigo-600 px-1.5 text-base hover:bg-indigo-700 dark:bg-indigo-600 dark:text-white hover:dark:bg-indigo-700">
                <Link className="px-5 py-2" to={"/signup"}>
                  Try Now
                </Link>
              </Button>
            )}
            {!user?.token ? (
              <Button variant="link" className="text-base">
                <Link to={"/contact?target=sales&reason=demo"}>Request Demo</Link>
              </Button>
            ) : null}
          </div>
        </motion.div>
        <div
          className="absolute right-10 hidden h-[65vh] w-[45vw] cursor-pointer pt-10 lg:flex"
          onClick={() => {
            if (isChanging || isLoading) return;
            nextImage();
          }}
        />
      </div>
    </AuroraBackground>
  );
}
