import axios from "axios";
import Cookies from "universal-cookie";

// Providers
import { Provider } from "react-redux";
import { ThemeProvider } from "@/components/theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Stores
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./stores/userSlice";
import themeReducer from "./stores/themeSlice";
import navReducer from "./stores/navSlice";
import systemReducer from "./stores/systemSlice";
import configReducer from "./stores/configSlice";

// Navigation components
import Navbar from "./pages/Navbar";
import Footer from "./pages/Footer";

import Main from "./pages/Main";
import Sidebar from "./pages/Sidebar";
import Overlay from "./pages/Overlay";

const queryClient = new QueryClient();

function App(props) {
  if (!import.meta.env.SSR) {
    axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json";

    if (props.user?.token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + props.user?.token;

      const cookies = new Cookies(null, { path: "/" });
      var expires = new Date();
      expires.setDate(expires.getDate() + 30);
      cookies.set("104jwc", props.user?.token, { expires });
    }
  }

  const reduxStore = configureStore({
    reducer: {
      user: userReducer,
      theme: themeReducer,
      nav: navReducer,
      system: systemReducer,
      config: configReducer,
    },
    preloadedState: {
      user: props.user,
      theme: { current: props?.user?.session?.user?.theme },
      system: { build: props?.build, instance: props?.instance, os: props?.os },
    },
  });

  return (
    <Provider store={reduxStore}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider storageKey="vite-ui-theme">
          <Navbar />
          <Sidebar />
          <Main />
          <Footer />
          <Overlay />
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
