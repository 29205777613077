import axios from "axios";

export function clearStorageSafe() {
  let visitRecorded = localStorage.getItem("visit_recorded");
  let utmUci = localStorage.getItem("utm_uci");
  let utmSource = localStorage.getItem("utm_source");
  let utmMedium = localStorage.getItem("utm_medium");
  let utmCampaign = localStorage.getItem("utm_campaign");
  let utmDebug = localStorage.getItem("utm_debug");

  localStorage.clear();

  if (visitRecorded) localStorage.setItem("visit_recorded", visitRecorded);
  if (utmUci) localStorage.setItem("utm_uci", utmUci);
  if (utmSource) localStorage.setItem("utm_source", utmSource);
  if (utmMedium) localStorage.setItem("utm_medium", utmMedium);
  if (utmCampaign) localStorage.setItem("utm_campaign", utmCampaign);
  if (utmDebug) localStorage.setItem("utm_debug", utmDebug);
}

export function getUtm() {
  let utmUci = localStorage.getItem("utm_uci");
  let utmSource = localStorage.getItem("utm_source");
  let utmMedium = localStorage.getItem("utm_medium");
  let utmCampaign = localStorage.getItem("utm_campaign");
  let utmDebug = localStorage.getItem("utm_debug") === "true";
  return {
    uci: utmUci,
    utmSource,
    utmMedium,
    utmCampaign,
    debug: utmDebug,
  };
}

export async function recordUtm() {
  const visitRecorded = localStorage.getItem("visit_recorded") === "true";
  const url = new URL(window.location.href);
  if (visitRecorded) {
    // Clear if opened the link with utm info
    if (url.searchParams.get("utm_source")?.length > 0) {
      url.searchParams.delete("uci");
      url.searchParams.delete("utm_source");
      url.searchParams.delete("utm_medium");
      url.searchParams.delete("utm_campaign");
      url.searchParams.delete("debug");
      window.history.replaceState({}, "", url.toString());
    }
    return;
  }

  let utmUci = localStorage.getItem("utm_uci");
  let utmSource = localStorage.getItem("utm_source");
  let utmMedium = localStorage.getItem("utm_medium");
  let utmCampaign = localStorage.getItem("utm_campaign");
  let utmDebug = localStorage.getItem("utm_debug") === "true";

  if (!utmUci?.length || (!utmSource?.length && url.searchParams.get("utm_source")?.length > 0)) {
    // If UTM not recorded creating recording new one
    utmUci = url.searchParams.get("uci");
    utmSource = url.searchParams.get("utm_source");
    utmMedium = url.searchParams.get("utm_medium");
    utmCampaign = url.searchParams.get("utm_campaign");
    utmDebug = url.searchParams.get("debug") === "true";

    if (!utmUci?.length) {
      utmUci = parseInt(1000000000 + Math.random() * 1000000000) + "";
    }

    localStorage.setItem("utm_uci", utmUci);
    localStorage.setItem("utm_source", utmSource ?? "");
    localStorage.setItem("utm_medium", utmMedium ?? "");
    localStorage.setItem("utm_campaign", utmCampaign ?? "");
    localStorage.setItem("utm_debug", utmDebug + "");
  }

  if (url.searchParams.get("utm_source")?.length > 0) {
    url.searchParams.delete("uci");
    url.searchParams.delete("utm_source");
    url.searchParams.delete("utm_medium");
    url.searchParams.delete("utm_campaign");
    url.searchParams.delete("debug");
    window.history.replaceState({}, "", url.toString());
  }

  if (!visitRecorded) {
    await axios.post("events/visit", {
      uci: utmUci,
      utmSource: utmSource,
      utmMedium: utmMedium,
      utmCampaign: utmCampaign,
      debug: utmDebug,
    });
    localStorage.setItem("visit_recorded", "true");
  }
}
