import React from "react";
import { Link } from "react-router-dom";
import { cn } from "@/lib/utils";
import { Button } from "../ui/button";

import LogoIcon from "@/assets/logo-icon.svg";
import LogoFull from "@/assets/logo-full-landing.svg";
import LogoFullWhite from "@/assets/logo-full-landing-white.svg";

export default function NavLogoLanding({ variant, className, to, onClick }) {
  const content =
    variant === "form" ? (
      <p className="flex flex-row text-nowrap font-serious text-base font-black text-neutral-700 hover:text-black dark:text-neutral-200">
        Ten4 Intelligence
      </p>
    ) : (
      <>
        <img src={LogoIcon} alt="logo" className="flex h-9 p-0 md:hidden" />
        <img src={LogoFull} alt="logo" className="hidden h-9 p-0 md:flex dark:md:hidden" />
        <img src={LogoFullWhite} alt="logo" className="hidden h-9 p-0 md:hidden dark:md:flex" />
      </>
    );
  return onClick ? (
    <Button variant="link" className={cn(className)} onClick={onClick}>
      {content}
    </Button>
  ) : (
    <Link to={to ?? "/"} className={cn("flex flex-row", className)}>
      {content}
    </Link>
  );
}
