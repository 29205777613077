import { motion } from "framer-motion";
import { Img } from "react-image";

import { Check } from "lucide-react";
import LaptopDatImg from "@/assets/laptop-dat.avif";
import LaptopCostImg from "@/assets/laptop-light-fleet.avif";
import LaptopTemplateImg from "@/assets/laptop-light-template.avif";
import ExtensionCalculator from "@/assets/extension-calculator.avif";
import ExtensionTemplate from "@/assets/extension-template.avif";
import ProfitCalculator from "@/assets/extension-calculator-pretty.avif";
import { cn } from "@/lib/utils";
import { useEffect, useRef, useState } from "react";

const Description = ({ text, description, image, list }) => {
  return (
    <div className="flex flex-col justify-center text-lg 2xl:px-20 2xl:text-xl">
      <div className="whitespace-nowrap pb-4 font-serious text-2xl font-black dark:text-gray-200 lg:text-2xl xl:text-3xl 2xl:text-4xl">
        {text}
      </div>
      <div className="pb-6 font-light">{description}</div>
      <div className="-ml-6 flex min-h-[250px] items-center pb-14 pt-3 lg:hidden">{image}</div>
      <div className="flex flex-col space-y-0.5 font-light lg:space-y-1">
        {list?.map((text, i) => (
          <div key={i} className="flex flex-row space-x-4 font-light leading-10">
            <Check className="mt-1 min-h-7 min-w-7 text-green-600 lg:min-h-8 lg:min-w-8" /> <div>{text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Container = ({ children, className }) => {
  return (
    <motion.div
      className={cn("flex w-full items-center justify-center lg:w-1/2", className)}
      initial={{ opacity: 0.0, y: 40 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.3, duration: 0.8, ease: "easeInOut" }}
      viewport={{ once: true }}
    >
      {children}
    </motion.div>
  );
};

const Image = ({ src, corner, isVisible, alt, classes }) => {
  if (!isVisible) return null;
  return (
    <div className="relative px-8 2xl:px-14">
      <Img className="-mb-6" alt={alt} loading="lazy" src={src} />
      <Img
        className={cn(
          "absolute -top-6 right-2 max-h-[180px] rounded-lg border border-gray-300 shadow-2xl shadow-gray-600 lg:-top-4 lg:max-h-[240px] xl:-top-12 xl:max-h-[280px] 2xl:-top-14 2xl:max-h-[340px]",
          classes?.corner,
        )}
        loading="lazy"
        alt={alt + "-corner"}
        src={corner}
      />
    </div>
  );
};

export function LandingDetailsSection() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isVisible) return;

    setIsVisible(() => window.scrollY > 1200);
    const listener = () => {
      setIsVisible(() => window.scrollY > 1200);
    };

    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, [isVisible]);

  return (
    <div className="relative flex w-full flex-col space-y-16 px-6 pb-14 pt-16 lg:space-y-40 lg:px-20 lg:pb-40 lg:pt-32 xl:px-24 2xl:px-32">
      <div className="flex w-full flex-col lg:flex-row lg:space-x-14">
        <div className="flex w-full flex-col items-center justify-center lg:w-1/2">
          <Description
            text="Message Brokers"
            description="Create email templates that would be pre-filled with the load information when you click send directly from an eligible load board"
            image={<Image alt="emails" isVisible={isVisible} src={LaptopTemplateImg} corner={ExtensionTemplate} />}
            list={[
              "Send email in a single click",
              "Smart email templates",
              "Connect multiple emails",
              "Personalized email signatures",
            ]}
          />
        </div>
        <Container className="hidden lg:flex">
          <Image alt="emails" isVisible={isVisible} src={LaptopTemplateImg} corner={ExtensionTemplate} />
        </Container>
      </div>
      <div className="flex w-full flex-col lg:w-full lg:flex-row lg:space-x-14">
        <Container className="hidden lg:flex">
          <Image
            alt="costs"
            isVisible={isVisible}
            src={LaptopCostImg}
            corner={ProfitCalculator}
            classes={{ corner: "left-3 0 max-h-[120px] lg:max-h-[180px] xl:max-h-[220px] 2xl:max-h-[250px]" }}
          />
        </Container>
        <div className="flex w-full flex-col items-center justify-center lg:w-1/2">
          <Description
            text="Cost Analysis"
            image={
              <Image
                alt="emails"
                isVisible={isVisible}
                src={LaptopCostImg}
                corner={ProfitCalculator}
                classes={{ corner: " max-h-[130px] -top-4" }}
              />
            }
            description="Profitability is the best indicator to book a load, automatically calculate profit for loads you book and analyze your fleets costs at no effort"
            list={["Monthly costs projections", "Estimate expenses, profits or losses", "Smart load profit calculator"]}
          />
        </div>
      </div>
      <div className="flex w-full flex-col lg:flex-row lg:space-x-14">
        <div className="flex w-full flex-col items-center justify-center lg:w-1/2">
          <Description
            text="Chrome Extension"
            image={<Image alt="emails" isVisible={isVisible} src={LaptopTemplateImg} corner={ExtensionTemplate} />}
            description="Using a load board that is not supported? Not a problem! Our Chrome extension allows you to quickly send emails, estimate load costs and access truck information"
            list={[
              "Quick way to send emails",
              "Quick access to profit calculator",
              "Quick access to truck information",
            ]}
          />
        </div>
        <Container className="hidden lg:flex">
          <Image alt="plugin" isVisible={isVisible} src={LaptopDatImg} corner={ExtensionCalculator} />
        </Container>
      </div>
    </div>
  );
}
